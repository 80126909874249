import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbTypography from '../../components/common/IbTypography';
import IbInput from '../../components/common/IbInput';
import IbButton from '../../components/common/IbButton';
import IbSpin from '../../components/common/IbSpin';
import IbInfo from '../../components/common/IbInfo';
import { PasswordOptionsResponse, ResetPasswordRequest } from '../../../api';
import { passwordApi, userApi } from '../../apis';
import { getQueryVariable } from '../../utils/queryUtil';
import { getErrorMessage } from '../../utils/exceptionUtil';
import IbIcon from '../../components/common/IbIcon';

const CODE_KEY = 'code';
const USER_ID_KEY = 'userId';

const MAIN_CLASS = 'ib-reset-password-page';
const REDIRECT_TIMEOUT = 3000;

const ResetPasswordPage: React.FC = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState<string>();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState<PasswordOptionsResponse>();

  const code = getQueryVariable(CODE_KEY) || '';
  const userId = getQueryVariable(USER_ID_KEY) || '';

  const loadPasswordRequirementsAsync = async () => {
    try {
      const passwordRequirements = await passwordApi.getPasswordOptions();
      setPasswordRequirements(passwordRequirements.data);
    } catch (e) {
      setPasswordRequirements(undefined);
    }
  };

  const loadPasswordRequirements = () => {
    loadPasswordRequirementsAsync().finally();
  };
  useEffect(loadPasswordRequirements, []);

  const getPasswordRequirementsDescription = (validate = false) => {
    if (!passwordRequirements) return;

    // eslint-disable-next-line security/detect-non-literal-regexp
    const lengthRequirementRegexp = new RegExp(`.{${passwordRequirements.requiredLength},}`);
    const lowerCaseRequirement = /[a-z]/;
    const upperCaseRequirement = /[A-Z]/;
    const digitRequirement = /[0-9]/;
    const specialCharacterRequirement = /[^a-zA-Z0-9_\s]/;

    const startDescription = t('Password must contain');
    const lengthRequirement =
      !validate || !lengthRequirementRegexp.test(password)
        ? ` ${t('at least')} ${passwordRequirements.requiredLength} ${t('characters')}`
        : '';

    let lettersRequirement = lengthRequirement ? `, ${t('including')}` : '';
    if (
      passwordRequirements.requireLowercase &&
      passwordRequirements.requireUppercase &&
      (!validate || (!lowerCaseRequirement.test(password) && !upperCaseRequirement.test(password)))
    ) {
      lettersRequirement = lettersRequirement + ' ' + t('lowercase and uppercase Latin letters');
    } else if (passwordRequirements.requireLowercase && (!validate || !lowerCaseRequirement.test(password))) {
      lettersRequirement = lettersRequirement + ' ' + t('lowercase Latin letters');
    } else if (passwordRequirements.requireUppercase && (!validate || !upperCaseRequirement.test(password))) {
      lettersRequirement = lettersRequirement + ' ' + t('uppercase Latin letters');
    } else {
      lettersRequirement = '';
    }

    let nonLettersRequirement = lengthRequirement || lettersRequirement ? `, ${t('as well as')}` : '';
    if (
      passwordRequirements.requireDigit &&
      passwordRequirements.requireNonAlphanumeric &&
      (!validate || (!digitRequirement.test(password) && !specialCharacterRequirement.test(password)))
    ) {
      nonLettersRequirement = nonLettersRequirement + ' ' + t('at least one number and one special character');
    } else if (passwordRequirements.requireDigit && (!validate || !digitRequirement.test(password))) {
      nonLettersRequirement = nonLettersRequirement + ' ' + t('at least one number');
    } else if (
      passwordRequirements.requireNonAlphanumeric &&
      (!validate || !specialCharacterRequirement.test(password))
    ) {
      nonLettersRequirement = nonLettersRequirement + ' ' + t('at least one special character');
    } else {
      nonLettersRequirement = '';
    }

    if (lengthRequirement || lettersRequirement || nonLettersRequirement) {
      return startDescription + lengthRequirement + lettersRequirement + nonLettersRequirement;
    }
  };

  useEffect(() => {
    if (!successMessage) return;
    setTimeout(() => {
      push('/app/login');
    }, REDIRECT_TIMEOUT);
  }, [successMessage]);

  const handleSubmit = async () => {
    const isValid = password === confirmPassword;
    if (!isValid) {
      setErrorMessage(t('The entered passwords do not match'));
      return;
    }

    const validationError = getPasswordRequirementsDescription(true);
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    setErrorMessage('');
    setLoading(true);

    const options = { withCredentials: true };
    const request: ResetPasswordRequest = {
      newPassword: password,
      code,
    };

    try {
      await userApi.resetUserPassword(userId, request, options);
      setSuccessMessage(t('The password was successfully saved. You will be redirected to the login page.'));
    } catch (e) {
      setErrorMessage(getErrorMessage(e as Error));
    }
    setLoading(false);
  };

  const onPasswordChange = (value: string) => {
    setErrorMessage('');
    setPassword(value);
  };
  const onConfirmPasswordChange = (value: string) => {
    setErrorMessage('');
    setConfirmPassword(value);
  };
  const onShowPasswordClick = () => setShowPassword(!showPassword);
  const onShowConfirmPasswordClick = () => setShowConfirmPassword(!showConfirmPassword);
  const renderTogglePasswordIcon = (show: boolean) =>
    show ? <IbIcon iconName="preview-open" /> : <IbIcon iconName="preview-close-one" />;

  return (
    <div className={MAIN_CLASS}>
      {loading && <IbSpin />}
      <div className={`${MAIN_CLASS}__title`}>{t('Create a password')}</div>
      {successMessage && <IbInfo status="success">{successMessage}</IbInfo>}
      {passwordRequirements && !successMessage && (
        <IbInfo status="error">{getPasswordRequirementsDescription()}</IbInfo>
      )}
      <div className={`${MAIN_CLASS}__form`}>
        <div className={`${MAIN_CLASS}__input-label`}>{t('Password')}</div>
        <IbInput
          disabled={loading || !!successMessage}
          password={!showPassword}
          status={errorMessage ? 'error' : 'default'}
          suffix={<IbButton icon={renderTogglePasswordIcon(showPassword)} type="icon" onClick={onShowPasswordClick} />}
          value={password}
          onChange={onPasswordChange}
        />
        {errorMessage && (
          <IbTypography.Paragraph error type="descriptor">
            {errorMessage}
          </IbTypography.Paragraph>
        )}
        <div className={`${MAIN_CLASS}__input-label`}>{t('Confirm password')}</div>
        <IbInput
          disabled={loading || !!successMessage}
          password={!showConfirmPassword}
          status={errorMessage && password !== confirmPassword ? 'error' : 'default'}
          suffix={
            <IbButton
              icon={renderTogglePasswordIcon(showConfirmPassword)}
              type="icon"
              onClick={onShowConfirmPasswordClick}
            />
          }
          value={confirmPassword}
          onChange={onConfirmPasswordChange}
        />
      </div>
      <IbButton disabled={(!password && !confirmPassword) || !!successMessage} onClick={handleSubmit}>
        {t('Save and continue')}
      </IbButton>
    </div>
  );
};

export default ResetPasswordPage;
