import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import { useHistory } from 'react-router';
import { StatusCodes } from 'http-status-codes';
import { useTranslation } from 'react-i18next';
import store from 'store';

import './index.less';

import IbCheckbox from '../../components/common/IbCheckbox';
import IbButton from '../../components/common/IbButton';
import IbInput from '../../components/common/IbInput';
import IbIcon from '../../components/common/IbIcon';
import IbSpin from '../../components/common/IbSpin';
import IbInfo from '../../components/common/IbInfo';
import { LoginRequestModel } from '../../../api';
import { accountApi } from '../../apis';
import { getQueryVariable } from '../../utils/queryUtil';

const USERNAME_KEY = 'username';
const MAIN_CLASS = 'ib-login-page';

const LoginPage: React.FC = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const returnUrl = getQueryVariable('ReturnUrl');
  const loginDisabled = !password && !email;
  const inputStatus = errorMessage ? 'error' : 'default';

  const loadData = () => {
    const username = store.get(USERNAME_KEY);
    if (username) {
      setEmail(username);
    }
  };
  useEffect(loadData, []);

  const onRememberMeCheck = () => setRememberMe(!rememberMe);
  const onRegisterButtonClick = () => push('/app/trial/request');
  const onForgotPasswordButtonClick = () => push('/app/forgot-password');
  const onEmailChange = (value: string) => setEmail(value);
  const onPasswordChange = (value: string) => setPassword(value);
  const onShowPasswordClick = () => setShowPassword(!showPassword);

  const handleSubmit = async () => {
    setLoading(true);
    setErrorMessage('');

    const options = { withCredentials: true };
    const request: LoginRequestModel = {
      username: email,
      password: password,
      rememberMe: rememberMe,
      returnUrl: returnUrl,
    };

    try {
      const response = await accountApi.processAccountLogin(request, options);
      if (response.status === StatusCodes.OK) {
        store.set(USERNAME_KEY, request.username);
        window.location.href = response.data.redirectUrl || '/';
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage(t('Failed to sign in to your account. Please check your email and password and try again.'));
    }
  };

  const renderTogglePasswordIcon = () =>
    showPassword ? <IbIcon iconName="preview-open" /> : <IbIcon iconName="preview-close-one" />;

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      await handleSubmit();
    }
  };

  return (
    <div className={MAIN_CLASS} onKeyDown={handleKeyDown}>
      {loading && <IbSpin />}
      <div className={`${MAIN_CLASS}__title`}>{t('Log in')}</div>
      {errorMessage && <IbInfo status="error">{errorMessage}</IbInfo>}
      <div className={`${MAIN_CLASS}__form`}>
        <div className={`${MAIN_CLASS}__input-label`}>Email</div>
        <IbInput disabled={loading} status={inputStatus} value={email} onChange={onEmailChange} />
        <div className={`${MAIN_CLASS}__input-label`}>{t('Password')}</div>
        <IbInput
          disabled={loading}
          password={!showPassword}
          status={inputStatus}
          suffix={<IbButton icon={renderTogglePasswordIcon()} type="icon" onClick={onShowPasswordClick} />}
          value={password}
          onChange={onPasswordChange}
        />
        <div className={`${MAIN_CLASS}__additional`}>
          <Space>
            <IbCheckbox value={rememberMe} onChange={onRememberMeCheck} />
            {t('Remember me')}
          </Space>
          <IbButton disabled={loading} type="link" onClick={onForgotPasswordButtonClick}>
            {t('Forgot password?')}
          </IbButton>
        </div>
      </div>
      <div className={`${MAIN_CLASS}__main-button`}>
        <IbButton disabled={loginDisabled || loading} onClick={handleSubmit}>
          {t('Log in')}
        </IbButton>
      </div>
      <div className={`${MAIN_CLASS}__register`}>
        <Space>
          {t("Don't have an account?")}
          <IbButton disabled={loading} type="link" onClick={onRegisterButtonClick}>
            {t('Register now')}
          </IbButton>
        </Space>
      </div>
    </div>
  );
};

export default LoginPage;
