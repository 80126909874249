import React, { MouseEventHandler } from 'react';
import PhoneInput, { Labels } from 'react-phone-number-input';
import i18next from 'i18next';
import ru from 'react-phone-number-input/locale/ru.json';
import en from 'react-phone-number-input/locale/en.json';

import './index.less';
import 'react-phone-number-input/style.css';

import { getDefaultIfUndefined } from '../../../utils/typeUtil';

const STATUS_DEFAULT = 'default';
const ON_CHANGE_DEFAULT = () => {};
const COUNTRY_DEFAULT = 'RU';
const LABELS = { ru, en } as { [key: string]: Labels };

const MAIN_CLASS_NAME = 'ib-phone-number-input';
const DISABLED_CLASS_NAME = `${MAIN_CLASS_NAME}_disabled`;

export interface IIbPhoneNumberInputProps {
  className?: string;
  value?: null | string;
  status?: 'default' | 'success' | 'error';
  disabled?: boolean;
  placeholder?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
}

const IbPhoneNumberInput: React.FC<IIbPhoneNumberInputProps> = ({
  className,
  value,
  status = STATUS_DEFAULT,
  disabled,
  placeholder,
  onChange = ON_CHANGE_DEFAULT,
  onBlur,
}) => {
  status = getDefaultIfUndefined(status, STATUS_DEFAULT);
  onChange = getDefaultIfUndefined(onChange, ON_CHANGE_DEFAULT);

  const classes = [MAIN_CLASS_NAME, `${MAIN_CLASS_NAME}_${status}`, disabled ? DISABLED_CLASS_NAME : null];
  className && classes.push(className);

  const onDivClick: MouseEventHandler<HTMLDivElement> = (e) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e.target === e.currentTarget && e.target && (e.target as any).querySelector('input').focus();

  return (
    <div className={classes.join(' ')} onClick={onDivClick}>
      <PhoneInput
        focusInputOnCountrySelection
        limitMaxLength
        defaultCountry={COUNTRY_DEFAULT}
        disabled={disabled}
        labels={LABELS[i18next.language]}
        placeholder={placeholder}
        value={value !== null ? value : undefined}
        onBlur={onBlur}
        onChange={onChange}
      />
    </div>
  );
};

export default IbPhoneNumberInput;
