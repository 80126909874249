import React from 'react';

import './index.less';
import IbIcon, { IbIconName } from '../IbIcon';

const MAIN_CLASS = 'ib-info';

export interface IIbInfoProps {
  status?: 'success' | 'error';
}

const IbInfo: React.FC<IIbInfoProps> = ({ children, status }) => {
  let iconName: IbIconName = 'attention';
  const classes = [MAIN_CLASS];

  switch (status) {
    case 'error':
      iconName = 'attention';
      classes.push(`${MAIN_CLASS}_error`);
      break;
    case 'success':
      iconName = 'check-one';
      classes.push(`${MAIN_CLASS}_success`);
      break;
  }

  return (
    <div className={classes.join(' ')}>
      <IbIcon iconName={iconName} size={16} />
      {children}
    </div>
  );
};

export default IbInfo;
