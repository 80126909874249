import React from 'react';

import './index.less';

import { getDefaultIfUndefined } from '../../../utils/typeUtil';

const ON_CLICK_DEFAULT = () => {};
const PARAGRAPH_TYPE_DEFAULT = 'normal';
const PARAGRAPH_STRONG_DEFAULT = false;
const PARAGRAPH_DISABLED_DEFAULT = false;
const PARAGRAPH_LIGHT_DEFAULT = false;
const PARAGRAPH_ELLIPSIS_DEFAULT = false;
const PARAGRAPH_ERROR_DEFAULT = false;

const MAIN_CLASS_NAME = 'ib-typography';
const PARAGRAPH_CLASS_NAME = `${MAIN_CLASS_NAME}__paragraph`;
const STRONG_PARAGRAPH_CLASS_NAME = `${PARAGRAPH_CLASS_NAME}_strong`;
const DISABLED_PARAGRAPH_CLASS_NAME = `${PARAGRAPH_CLASS_NAME}_disabled`;
const LIGHT_PARAGRAPH_CLASS_NAME = `${PARAGRAPH_CLASS_NAME}_light`;
const ELLIPSIS_PARAGRAPH_CLASS_NAME = `${PARAGRAPH_CLASS_NAME}_ellipsis`;
const ERROR_PARAGRAPH_CLASS_NAME = `${PARAGRAPH_CLASS_NAME}_error`;

interface IIbParagraphProps {
  className?: string;
  type?: 'normal' | 'secondary' | 'descriptor';
  strong?: boolean;
  disabled?: boolean;
  light?: boolean;
  ellipsis?: boolean;
  error?: boolean;
}

const IbParagraph: React.FC<IIbParagraphProps> = ({
  className,
  type = PARAGRAPH_TYPE_DEFAULT,
  strong = PARAGRAPH_STRONG_DEFAULT,
  disabled = PARAGRAPH_DISABLED_DEFAULT,
  light = PARAGRAPH_LIGHT_DEFAULT,
  ellipsis = PARAGRAPH_ELLIPSIS_DEFAULT,
  error = PARAGRAPH_ERROR_DEFAULT,
  children,
}) => {
  type = getDefaultIfUndefined(type, PARAGRAPH_TYPE_DEFAULT);
  strong = getDefaultIfUndefined(strong, PARAGRAPH_STRONG_DEFAULT);
  disabled = getDefaultIfUndefined(disabled, PARAGRAPH_DISABLED_DEFAULT);
  light = getDefaultIfUndefined(light, PARAGRAPH_LIGHT_DEFAULT);
  ellipsis = getDefaultIfUndefined(ellipsis, PARAGRAPH_ELLIPSIS_DEFAULT);
  error = getDefaultIfUndefined(error, PARAGRAPH_ERROR_DEFAULT);

  const classes = [
    PARAGRAPH_CLASS_NAME,
    `${PARAGRAPH_CLASS_NAME}_${type}`,
    strong ? STRONG_PARAGRAPH_CLASS_NAME : '',
    disabled ? DISABLED_PARAGRAPH_CLASS_NAME : '',
    light ? LIGHT_PARAGRAPH_CLASS_NAME : '',
    ellipsis ? ELLIPSIS_PARAGRAPH_CLASS_NAME : '',
    error ? ERROR_PARAGRAPH_CLASS_NAME : '',
  ];
  className && classes.push(className);

  return <div className={classes.join(' ')}>{children}</div>;
};

type TypographySubComponents = {
  Paragraph: typeof IbParagraph;
};

export interface IIbTypographyProps {
  className?: string;
  onClick?: () => void;
}

const IbTypography: React.FC<IIbTypographyProps> & TypographySubComponents = ({
  className,
  children,
  onClick = ON_CLICK_DEFAULT,
}) => {
  onClick = getDefaultIfUndefined(onClick, ON_CLICK_DEFAULT);

  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  return (
    <div className={classes.join(' ')} onClick={onClick}>
      {children}
    </div>
  );
};

IbTypography.Paragraph = IbParagraph;

export default IbTypography;
