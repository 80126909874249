import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbPhoneNumberInput from '../../components/common/IbPhoneNumberInput';
import IbInput from '../../components/common/IbInput';
import IbButton from '../../components/common/IbButton';
import IbSpin from '../../components/common/IbSpin';
import IbInfo from '../../components/common/IbInfo';
import { AcceptInvitationRequest, PasswordOptionsResponse } from '../../../api';
import { passwordApi, userApi } from '../../apis';
import { phoneNumberIsValid } from '../../utils/stringUtils';
import IbTypography from '../../components/common/IbTypography';
import IbIcon from '../../components/common/IbIcon';
import { getQueryVariable } from '../../utils/queryUtil';
import { getErrorMessage } from '../../utils/exceptionUtil';

import {
  CODE_KEY,
  FAMILY_NAME_KEY,
  GIVEN_NAME_KEY,
  INPUT_LABEL_CLASS,
  INPUT_MAX_LENGTH,
  MAIN_CLASS,
  MIDDLE_NAME_KEY,
  PHONE_NUMBER_KEY,
  REDIRECT_TIMEOUT,
  USER_ID_KEY,
} from './const';

const AcceptInvitePage: React.FC = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(getQueryVariable(PHONE_NUMBER_KEY) || '');
  const [name, setName] = useState(
    `${getQueryVariable(FAMILY_NAME_KEY)} ${getQueryVariable(GIVEN_NAME_KEY)} ${getQueryVariable(MIDDLE_NAME_KEY)}`
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPhoneNumberValidation, setShowPhoneNumberValidation] = useState(false);
  const [showNameValidation, setShowNameValidation] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState<PasswordOptionsResponse>();

  const phoneNumberT = phoneNumber?.trim();
  const nameT = name.trim();

  const code = getQueryVariable(CODE_KEY) || '';
  const userId = getQueryVariable(USER_ID_KEY) || '';

  const canSave = nameT && phoneNumberT && password && confirmPassword;

  const loadPasswordRequirementsAsync = async () => {
    try {
      const passwordRequirements = await passwordApi.getPasswordOptions();
      setPasswordRequirements(passwordRequirements.data);
    } catch (e) {
      setPasswordRequirements(undefined);
    }
  };
  const loadPasswordRequirements = () => {
    loadPasswordRequirementsAsync().finally();
  };
  useEffect(loadPasswordRequirements, []);

  const getPasswordRequirementsDescription = (validate = false) => {
    if (!passwordRequirements) return;

    // eslint-disable-next-line security/detect-non-literal-regexp
    const lengthRequirementRegexp = new RegExp(`.{${passwordRequirements.requiredLength},}`);
    const lowerCaseRequirement = /[a-z]/;
    const upperCaseRequirement = /[A-Z]/;
    const digitRequirement = /[0-9]/;
    const specialCharacterRequirement = /[^a-zA-Z0-9_\s]/;

    const startDescription = t('Password must contain');
    const lengthRequirement =
      !validate || !lengthRequirementRegexp.test(password)
        ? ` ${t('at least')} ${passwordRequirements.requiredLength} ${t('characters')}`
        : '';

    let lettersRequirement = lengthRequirement ? `, ${t('including')}` : '';
    if (
      passwordRequirements.requireLowercase &&
      passwordRequirements.requireUppercase &&
      (!validate || (!lowerCaseRequirement.test(password) && !upperCaseRequirement.test(password)))
    ) {
      lettersRequirement = lettersRequirement + ' ' + t('lowercase and uppercase Latin letters');
    } else if (passwordRequirements.requireLowercase && (!validate || !lowerCaseRequirement.test(password))) {
      lettersRequirement = lettersRequirement + ' ' + t('lowercase Latin letters');
    } else if (passwordRequirements.requireUppercase && (!validate || !upperCaseRequirement.test(password))) {
      lettersRequirement = lettersRequirement + ' ' + t('uppercase Latin letters');
    } else {
      lettersRequirement = '';
    }

    let nonLettersRequirement = lengthRequirement || lettersRequirement ? `, ${t('as well as')}` : '';
    if (
      passwordRequirements.requireDigit &&
      passwordRequirements.requireNonAlphanumeric &&
      (!validate || (!digitRequirement.test(password) && !specialCharacterRequirement.test(password)))
    ) {
      nonLettersRequirement = nonLettersRequirement + ' ' + t('at least one number and one special character');
    } else if (passwordRequirements.requireDigit && (!validate || !digitRequirement.test(password))) {
      nonLettersRequirement = nonLettersRequirement + ' ' + t('at least one number');
    } else if (
      passwordRequirements.requireNonAlphanumeric &&
      (!validate || !specialCharacterRequirement.test(password))
    ) {
      nonLettersRequirement = nonLettersRequirement + ' ' + t('at least one special character');
    } else {
      nonLettersRequirement = '';
    }

    if (lengthRequirement || lettersRequirement || nonLettersRequirement) {
      return startDescription + lengthRequirement + lettersRequirement + nonLettersRequirement;
    }
  };

  useEffect(() => {
    if (!successMessage) return;
    setTimeout(() => {
      push('/app/login');
    }, REDIRECT_TIMEOUT);
  }, [successMessage]);

  const handleSubmit = async () => {
    const isPhoneNumberValid = !!phoneNumberT && phoneNumberIsValid(phoneNumberT);

    if (!isPhoneNumberValid) {
      setLoading(false);
      setShowPhoneNumberValidation(!isPhoneNumberValid);
    }

    const isValid = password === confirmPassword;
    if (!isValid) {
      setErrorMessage(t('The entered passwords do not match'));
      return;
    }

    const validationError = getPasswordRequirementsDescription(true);
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    if (!isPhoneNumberValid) return;

    let familyName = '';
    let givenName = '';
    let middleName = '';

    const names = nameT.split(' ', 3);
    if (names.length === 1) {
      setShowNameValidation(true);
      return;
    }
    if (names.length >= 2) {
      familyName = names[0].trim();
      givenName = names[1].trim();
    }
    if (names.length >= 3) {
      middleName = names[2].trim();
    }

    setLoading(true);
    setErrorMessage('');
    setShowPhoneNumberValidation(false);
    setShowNameValidation(false);

    const options = { withCredentials: true };

    const request: AcceptInvitationRequest = {
      password,
      code,
      phoneNumber: phoneNumberT,
      fullName: middleName ? `${familyName} ${givenName} ${middleName}` : `${familyName} ${givenName}`,
      shortName: middleName
        ? `${familyName} ${givenName.substring(0, 1)}.${middleName.substring(0, 1)}.`
        : `${familyName} ${givenName.substring(0, 1)}.`,
      familyName,
      givenName,
      middleName,
    };

    try {
      await userApi.acceptUserInvitation(userId, request, options);
      setSuccessMessage(t('The user was successfully saved. You will be redirected to the login page.'));
    } catch (e) {
      setErrorMessage(getErrorMessage(e as Error));
    }
    setLoading(false);
  };

  const onPasswordChange = (value: string) => {
    setErrorMessage('');
    setPassword(value);
  };
  const onConfirmPasswordChange = (value: string) => {
    setErrorMessage('');
    setConfirmPassword(value);
  };
  const onNameChange = (value: string) => setName(value);
  const onPhoneNumberChange = (value: string) => setPhoneNumber(value);

  const onShowPasswordClick = () => setShowPassword(!showPassword);
  const onShowConfirmPasswordClick = () => setShowConfirmPassword(!showConfirmPassword);
  const renderTogglePasswordIcon = (show: boolean) =>
    show ? <IbIcon iconName="preview-open" /> : <IbIcon iconName="preview-close-one" />;

  return (
    <div className={MAIN_CLASS}>
      {loading && <IbSpin />}
      <div className={`${MAIN_CLASS}__title`}>{t('Register')}</div>
      {successMessage && <IbInfo status="success">{successMessage}</IbInfo>}
      {passwordRequirements && !successMessage && (
        <IbInfo status="error">{getPasswordRequirementsDescription()}</IbInfo>
      )}
      <div className={`${MAIN_CLASS}__form`}>
        <div className={`${MAIN_CLASS}__input-label`}>{t('Password')}</div>
        <IbInput
          disabled={loading || !!successMessage}
          password={!showPassword}
          status={errorMessage ? 'error' : 'default'}
          suffix={<IbButton icon={renderTogglePasswordIcon(showPassword)} type="icon" onClick={onShowPasswordClick} />}
          value={password}
          onChange={onPasswordChange}
        />
        {errorMessage && (
          <IbTypography.Paragraph error type="descriptor">
            {errorMessage}
          </IbTypography.Paragraph>
        )}
        <div className={`${MAIN_CLASS}__input-label`}>{t('Confirm password')}</div>
        <IbInput
          disabled={loading || !!successMessage}
          password={!showConfirmPassword}
          status={errorMessage && password !== confirmPassword ? 'error' : 'default'}
          suffix={
            <IbButton
              icon={renderTogglePasswordIcon(showConfirmPassword)}
              type="icon"
              onClick={onShowConfirmPasswordClick}
            />
          }
          value={confirmPassword}
          onChange={onConfirmPasswordChange}
        />
        <div className={INPUT_LABEL_CLASS}>{t('Phone number')}</div>
        <IbPhoneNumberInput
          disabled={loading}
          status={showPhoneNumberValidation ? 'error' : 'default'}
          value={phoneNumber}
          onChange={onPhoneNumberChange}
        />
        {showPhoneNumberValidation && (
          <IbTypography.Paragraph error type="descriptor">
            {t('Please enter a valid phone number')}
          </IbTypography.Paragraph>
        )}
        <div className={INPUT_LABEL_CLASS}>{t('Full name')}</div>
        <IbInput disabled={loading} maxLength={INPUT_MAX_LENGTH} value={name} onChange={onNameChange} />
        {showNameValidation && (
          <IbTypography.Paragraph error type="descriptor">
            {t('Please enter a full name')}
          </IbTypography.Paragraph>
        )}
      </div>
      <div className={`${MAIN_CLASS}__button`}>
        <IbButton disabled={loading || !canSave} onClick={handleSubmit}>
          {t('Save')}
        </IbButton>
      </div>
    </div>
  );
};

export default AcceptInvitePage;
