// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const CLIENT_CONFIG = window.elma.assistant.info.configuration.client;

export const PUBLIC_URL: string = CLIENT_CONFIG.services.authServer.publicUrl;

export const BASE_PATH: string = new URL(PUBLIC_URL).pathname.replace(/\/$/, '');

export const getCurrentPath = (subPath: string): string => `${BASE_PATH}${subPath}`;

export const LOCALE_PATH: string = getCurrentPath('/locales/{{lng}}/{{ns}}.json');
