import React, { useState } from 'react';
import { Form, Space } from 'antd';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbPhoneNumberInput from '../../components/common/IbPhoneNumberInput';
import IbInput from '../../components/common/IbInput';
import IbButton from '../../components/common/IbButton';
import IbSpin from '../../components/common/IbSpin';
import IbInfo from '../../components/common/IbInfo';
import { TrialCreationRequest } from '../../../api';
import { trialApi } from '../../apis';
import { emailIsValid, phoneNumberIsValid } from '../../utils/stringUtils';
import IbTypography from '../../components/common/IbTypography';

import { BUTTON_CLASS, INPUT_LABEL_CLASS, INPUT_MAX_LENGTH, MAIN_CLASS } from './const';

const RegisterPage: React.FC = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [trialId, setTrialId] = useState('');
  const [showEmailValidation, setShowEmailValidation] = useState(false);
  const [showPhoneNumberValidation, setShowPhoneNumberValidation] = useState(false);
  const [showNameValidation, setShowNameValidation] = useState(false);

  const emailT = email?.trim();
  const nameT = name?.trim();
  const companyNameT = companyName?.trim();
  const phoneNumberT = phoneNumber?.trim();

  const canRegister = nameT && emailT && phoneNumberT && companyNameT;

  const onLoginButtonClick = () => push('/app/login');
  const onBackButtonClick = () => push('/app/login');
  const onNameChange = (value: string) => setName(value);
  const onEmailChange = (value: string) => setEmail(value);
  const onPhoneNumberChange = (value: string) => setPhoneNumber(value);
  const onCompanyNameChange = (value: string) => setCompanyName(value);
  const onResendButtonClick = async () => {
    setLoading(true);
    try {
      setErrorMessage('');
      await trialApi.sendEmail(trialId);
      setSuccessMessage(t('The re-registration request has been successfully sent. Please check your email.'));
    } catch (e) {
      setErrorMessage(t('Server error when trying to retry the request. Please wait and try again.'));
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    let familyName = '';
    let givenName = '';
    let middleName = '';

    const names = nameT.split(' ', 3);
    if (names.length >= 2) {
      familyName = names[0].trim();
      givenName = names[1].trim();
    }
    if (names.length >= 3) {
      middleName = names[2].trim();
    }

    const isEmailValid = !!emailT && emailIsValid(emailT);
    const isPhoneNumberValid = !!phoneNumberT && phoneNumberIsValid(phoneNumberT);
    const isNameValid = names.length > 1;

    if (!isPhoneNumberValid || !isEmailValid || !isNameValid) {
      setLoading(false);
      setShowEmailValidation(!isEmailValid);
      setShowPhoneNumberValidation(!isPhoneNumberValid);
      setShowNameValidation(!isNameValid);
      setErrorMessage(t('Incorrect completion of the form. Please check your details and try again'));
      return;
    }

    setLoading(true);
    setErrorMessage('');
    setShowNameValidation(false);
    setShowEmailValidation(false);
    setShowPhoneNumberValidation(false);

    const request: TrialCreationRequest = {
      familyName,
      givenName,
      middleName,
      phoneNumber: phoneNumberT,
      companyName: companyNameT,
      email: emailT,
    };

    try {
      const trial = await trialApi.requestTrial(request);
      if (!trial.data.isNew) {
        setShowEmailValidation(true);
        setErrorMessage(
          t('The specified email has already been registered. Please change your email address and try again.')
        );
        setLoading(false);
        return;
      }
      setTrialId(trial.data.id);
      setSuccess(true);
    } catch (e) {
      setErrorMessage(t('Server error when trying to register. Please wait and try again'));
    }
    setLoading(false);
  };

  const renderSuccess = () => (
    <div className={MAIN_CLASS}>
      {loading && <IbSpin />}
      <div className={`${MAIN_CLASS}__title`}>{t('Thank you for registering!')}</div>
      {successMessage && <IbInfo status="success">{successMessage}</IbInfo>}
      {errorMessage && <IbInfo status="error">{errorMessage}</IbInfo>}
      <div className={`${MAIN_CLASS}__description`}>
        <span>{t('We have sent a confirmation email to your email. Please open it and complete registration.')}</span>
        <span>{t('If the letter did not arrive, try sending the request again.')}</span>
      </div>
      <div className={BUTTON_CLASS}>
        <IbButton type="fill" onClick={onBackButtonClick}>
          {t('Log in form')}
        </IbButton>
      </div>
      <div className={BUTTON_CLASS}>
        <IbButton disabled={!!successMessage} type="link" onClick={onResendButtonClick}>
          {t('Resend')}
        </IbButton>
      </div>
    </div>
  );

  return success ? (
    renderSuccess()
  ) : (
    <Form className={MAIN_CLASS} onFinish={handleSubmit}>
      {loading && <IbSpin />}
      <div className={`${MAIN_CLASS}__title`}>{t('Register')}</div>
      {errorMessage && <IbInfo status="error">{errorMessage}</IbInfo>}
      <div className={`${MAIN_CLASS}__form`}>
        <div className={INPUT_LABEL_CLASS}>{t('Full name')}</div>
        <IbInput
          disabled={loading}
          maxLength={INPUT_MAX_LENGTH}
          status={showNameValidation ? 'error' : 'default'}
          value={name}
          onChange={onNameChange}
        />
        {showNameValidation && (
          <IbTypography.Paragraph error type="descriptor">
            {t('Please enter a full name')}
          </IbTypography.Paragraph>
        )}
        <div className={INPUT_LABEL_CLASS}>Email</div>
        <IbInput
          disabled={loading}
          maxLength={INPUT_MAX_LENGTH}
          status={showEmailValidation ? 'error' : 'default'}
          value={email}
          onChange={onEmailChange}
        />
        {showEmailValidation && (
          <IbTypography.Paragraph error type="descriptor">
            {t('Please enter a valid email')}
          </IbTypography.Paragraph>
        )}
        <div className={INPUT_LABEL_CLASS}>{t('Phone number')}</div>
        <IbPhoneNumberInput
          disabled={loading}
          status={showPhoneNumberValidation ? 'error' : 'default'}
          value={phoneNumber}
          onChange={onPhoneNumberChange}
        />
        {showPhoneNumberValidation && (
          <IbTypography.Paragraph error type="descriptor">
            {t('Please enter a valid phone number')}
          </IbTypography.Paragraph>
        )}
        <div className={INPUT_LABEL_CLASS}>{t('Company name')}</div>
        <IbInput disabled={loading} maxLength={INPUT_MAX_LENGTH} value={companyName} onChange={onCompanyNameChange} />
      </div>
      <div className={BUTTON_CLASS}>
        <IbButton disabled={!canRegister || loading} onClick={handleSubmit}>
          {t('Register')}
        </IbButton>
      </div>
      <div className={`${MAIN_CLASS}__login`}>
        <Space>
          {t('Already have an account?')}
          <IbButton disabled={loading} type="link" onClick={onLoginButtonClick}>
            {t('Log in please')}
          </IbButton>
        </Space>
      </div>
    </Form>
  );
};

export default RegisterPage;
