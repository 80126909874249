import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbSpin from '../../components/common/IbSpin';
import { getQueryVariable } from '../../utils/queryUtil';
import { accountApi } from '../../apis';
import { LogoutRequestModel } from '../../../api';

const MAIN_CLASS = 'ib-logout-page';

const LogoutPage: React.FC = () => {
  const { t } = useTranslation();

  const processLogoutAsync = async () => {
    const logoutId = getQueryVariable('logoutId');
    const options = { withCredentials: true };

    const request: LogoutRequestModel = { logoutId };
    try {
      const response = await accountApi.processAccountLogout(request, options);
      window.location.href = response.data.postLogoutRedirectUri || '/';
    } catch (e) {
      return;
    }
  };

  const processLogout = () => {
    processLogoutAsync();
  };
  useEffect(processLogout, []);

  return (
    <div className={MAIN_CLASS}>
      <IbSpin />
      <div className={`${MAIN_CLASS}__title`}>{t('Logout in progress')}</div>
      <div className={`${MAIN_CLASS}__description`}>{t('Please wait')}</div>
    </div>
  );
};

export default LogoutPage;
