export const MAIN_CLASS = 'ib-accept-invite-page';

export const INPUT_LABEL_CLASS = `${MAIN_CLASS}__input-label`;

export const INPUT_MAX_LENGTH = 64;

export const REDIRECT_TIMEOUT = 3000;

export const CODE_KEY = 'code';
export const USER_ID_KEY = 'userId';
export const PHONE_NUMBER_KEY = 'phoneNumber';
export const FAMILY_NAME_KEY = 'familyName';
export const GIVEN_NAME_KEY = 'givenName';
export const MIDDLE_NAME_KEY = 'middleName';
