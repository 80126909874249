import React from 'react';
import { Layout } from 'antd';

import { LogoElmaBot } from '../assets';
import { getCurrentPath } from '../utils/configUtil';

const { Content } = Layout;

const MAIN_CLASS = 'auth-layout';

const AuthLayout: React.FC = (props) => {
  const { children } = props;

  return (
    <Layout className={MAIN_CLASS}>
      <div className={`${MAIN_CLASS}__logo-container`}>
        <a href={getCurrentPath('/app/login')}>
          <LogoElmaBot />
        </a>
      </div>
      <Content className={`${MAIN_CLASS}__content`}>{children}</Content>
    </Layout>
  );
};

export default AuthLayout;
