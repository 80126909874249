import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router';

import './App.less';
import AuthLayout from './layouts/AuthLayout';
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import RegisterPage from './pages/RegisterPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import AcceptInvitePage from './pages/AcceptInvitePage';
import ConfirmTrialPage from './pages/ConfirmTrialPage';
import { browserHistory } from './utils/historyUtil';

function App(): JSX.Element {
  return (
    <Router history={browserHistory}>
      <Switch>
        <Route
          exact
          path="/app/login"
          render={() => (
            <AuthLayout>
              <LoginPage />
            </AuthLayout>
          )}
        />
        <Route
          exact
          path="/app/logout"
          render={() => (
            <AuthLayout>
              <LogoutPage />
            </AuthLayout>
          )}
        />
        <Route
          exact
          path="/app/forgot-password"
          render={() => (
            <AuthLayout>
              <ForgotPasswordPage />
            </AuthLayout>
          )}
        />
        <Route
          exact
          path="/app/password/reset"
          render={() => (
            <AuthLayout>
              <ResetPasswordPage />
            </AuthLayout>
          )}
        />
        <Route
          exact
          path="/app/accept-invite"
          render={() => (
            <AuthLayout>
              <AcceptInvitePage />
            </AuthLayout>
          )}
        />
        <Route
          exact
          path="/app/trial/request"
          render={() => (
            <AuthLayout>
              <RegisterPage />
            </AuthLayout>
          )}
        />
        <Route
          exact
          path="/app/trial/confirm"
          render={() => (
            <AuthLayout>
              <ConfirmTrialPage />
            </AuthLayout>
          )}
        />
        <Route path="*">
          <Redirect to="/app/login" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
