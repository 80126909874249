/* tslint:disable */
/* eslint-disable */
/**
 * Аутентификация ELMA Bot
 * Аутентификация пользователей, администраторов, владельцев и т.д.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * Модель информации о приложении.
 * @export
 * @interface AboutModel
 */
export interface AboutModel {
    /**
     * Наименование.
     * @type {string}
     * @memberof AboutModel
     */
    'name'?: string | null;
    /**
     * Описание.
     * @type {string}
     * @memberof AboutModel
     */
    'description'?: string | null;
    /**
     * Конечная точка.
     * @type {string}
     * @memberof AboutModel
     */
    'endpoint'?: string | null;
    /**
     * Версия.
     * @type {string}
     * @memberof AboutModel
     */
    'version'?: string | null;
}
/**
 * Модель запроса на принятие приглашения.
 * @export
 * @interface AcceptInvitationRequest
 */
export interface AcceptInvitationRequest {
    /**
     * Код приглашения пользователя.
     * @type {string}
     * @memberof AcceptInvitationRequest
     */
    'code': string;
    /**
     * Пароль пользователя.
     * @type {string}
     * @memberof AcceptInvitationRequest
     */
    'password': string;
    /**
     * Номер телефона пользователя.
     * @type {string}
     * @memberof AcceptInvitationRequest
     */
    'phoneNumber': string;
    /**
     * Полное имя пользователя.
     * @type {string}
     * @memberof AcceptInvitationRequest
     */
    'fullName': string;
    /**
     * Краткое имя пользователя.
     * @type {string}
     * @memberof AcceptInvitationRequest
     */
    'shortName': string;
    /**
     * Фамилия пользователя.
     * @type {string}
     * @memberof AcceptInvitationRequest
     */
    'familyName': string;
    /**
     * Имя пользователя.
     * @type {string}
     * @memberof AcceptInvitationRequest
     */
    'givenName': string;
    /**
     * Отчество пользователя.
     * @type {string}
     * @memberof AcceptInvitationRequest
     */
    'middleName'?: string | null;
}
/**
 * Модель информации об аккаунте.
 * @export
 * @interface AccountInfoModel
 */
export interface AccountInfoModel {
    /**
     * Аутентифицирован?
     * @type {boolean}
     * @memberof AccountInfoModel
     */
    'authenticated'?: boolean;
    /**
     * Иvя пользователя.
     * @type {string}
     * @memberof AccountInfoModel
     */
    'username'?: string | null;
}
/**
 * Модель информации об ошибке.
 * @export
 * @interface ErrorInfoModel
 */
export interface ErrorInfoModel {
    /**
     * Сообщение.
     * @type {string}
     * @memberof ErrorInfoModel
     */
    'message'?: string | null;
    /**
     * Описание.
     * @type {string}
     * @memberof ErrorInfoModel
     */
    'description'?: string | null;
}
/**
 * Модель события.
 * @export
 * @interface EventModel
 */
export interface EventModel {
    /**
     * ИД события.
     * @type {string}
     * @memberof EventModel
     */
    'id'?: string | null;
    /**
     * Тип события.
     * @type {string}
     * @memberof EventModel
     */
    'type'?: string | null;
    /**
     * Аргументы события.
     * @type {{ [key: string]: any; }}
     * @memberof EventModel
     */
    'arguments'?: { [key: string]: any; } | null;
}
/**
 *
 * @export
 * @interface EventModelPaginationResponse
 */
export interface EventModelPaginationResponse {
    /**
     *
     * @type {Array<EventModel>}
     * @memberof EventModelPaginationResponse
     */
    'items'?: Array<EventModel> | null;
    /**
     *
     * @type {boolean}
     * @memberof EventModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     *
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     *
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     *
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     *
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     *
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     *
     * @type {number}
     * @memberof EventModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * Модель запроса на отправку ссылки восстановления пароля.
 * @export
 * @interface ForgotPasswordRequest
 */
export interface ForgotPasswordRequest {
    /**
     * Логин или email пользователя.
     * @type {string}
     * @memberof ForgotPasswordRequest
     */
    'nameOrEmail': string;
}
/**
 * Модель информации о работоспособности.
 * @export
 * @interface HealthModel
 */
export interface HealthModel {
    /**
     * Статус работоспособности.
     * @type {string}
     * @memberof HealthModel
     */
    'status'?: string | null;
}
/**
 * Модель запроса на вход.
 * @export
 * @interface LoginRequestModel
 */
export interface LoginRequestModel {
    /**
     * Логин.
     * @type {string}
     * @memberof LoginRequestModel
     */
    'username'?: string | null;
    /**
     * Пароль.
     * @type {string}
     * @memberof LoginRequestModel
     */
    'password'?: string | null;
    /**
     * Запомнить меня?
     * @type {boolean}
     * @memberof LoginRequestModel
     */
    'rememberMe'?: boolean;
    /**
     * URL возврата.
     * @type {string}
     * @memberof LoginRequestModel
     */
    'returnUrl'?: string | null;
}
/**
 * Модель ответа на вход.
 * @export
 * @interface LoginResponseModel
 */
export interface LoginResponseModel {
    /**
     * URL перенаправления.
     * @type {string}
     * @memberof LoginResponseModel
     */
    'redirectUrl'?: string | null;
}
/**
 * Модель информации о выходе.
 * @export
 * @interface LogoutInfoModel
 */
export interface LogoutInfoModel {
    /**
     * ИД выхода.
     * @type {string}
     * @memberof LogoutInfoModel
     */
    'logoutId'?: string | null;
    /**
     * Показывать подтверждение?
     * @type {boolean}
     * @memberof LogoutInfoModel
     */
    'showLogoutPrompt'?: boolean;
}
/**
 * Модель запроса на выход.
 * @export
 * @interface LogoutRequestModel
 */
export interface LogoutRequestModel {
    /**
     * ИД выхода.
     * @type {string}
     * @memberof LogoutRequestModel
     */
    'logoutId'?: string | null;
}
/**
 * Модель ответа на выход.
 * @export
 * @interface LogoutResponseModel
 */
export interface LogoutResponseModel {
    /**
     * Наименование клиента.
     * @type {string}
     * @memberof LogoutResponseModel
     */
    'clientName'?: string | null;
    /**
     * URL фрейма для выхода.
     * @type {string}
     * @memberof LogoutResponseModel
     */
    'signOutIframeUrl'?: string | null;
    /**
     * URL перенаправления после выхода.
     * @type {string}
     * @memberof LogoutResponseModel
     */
    'postLogoutRedirectUri'?: string | null;
    /**
     * Схема аутентификации внешнего поставщика.
     * @type {string}
     * @memberof LogoutResponseModel
     */
    'externalAuthenticationScheme'?: string | null;
    /**
     * Требуется ли выход из внешнего поставщика.
     * @type {boolean}
     * @memberof LogoutResponseModel
     */
    'triggerExternalSignout'?: boolean;
    /**
     * ИД выхода.
     * @type {string}
     * @memberof LogoutResponseModel
     */
    'logoutId'?: string | null;
    /**
     * Показывать подтверждение?
     * @type {boolean}
     * @memberof LogoutResponseModel
     */
    'showLogoutPrompt'?: boolean;
}
/**
 * Запрос на изменение пароля.
 * @export
 * @interface PasswordChangingRequest
 */
export interface PasswordChangingRequest {
    /**
     * Текущий пароль.
     * @type {string}
     * @memberof PasswordChangingRequest
     */
    'currentPassword': string;
    /**
     * Новый пароль.
     * @type {string}
     * @memberof PasswordChangingRequest
     */
    'newPassword': string;
}
/**
 *
 * @export
 * @interface PasswordOptionsResponse
 */
export interface PasswordOptionsResponse {
    /**
     * Требуемая длина пароля.
     * @type {number}
     * @memberof PasswordOptionsResponse
     */
    'requiredLength': number;
    /**
     * Требуемое количество уникальных символов в пароле.
     * @type {number}
     * @memberof PasswordOptionsResponse
     */
    'requiredUniqueChars': number;
    /**
     * Требование необходимости содержания специальных символов в пароле.
     * @type {boolean}
     * @memberof PasswordOptionsResponse
     */
    'requireNonAlphanumeric': boolean;
    /**
     * Требование необходимости содержания строчных латинских букв в пароле.
     * @type {boolean}
     * @memberof PasswordOptionsResponse
     */
    'requireLowercase': boolean;
    /**
     * Требование необходимости содержания заглавных латинских букв в пароле.
     * @type {boolean}
     * @memberof PasswordOptionsResponse
     */
    'requireUppercase': boolean;
    /**
     * Требование необходимости содержания цифр в пароле.
     * @type {boolean}
     * @memberof PasswordOptionsResponse
     */
    'requireDigit': boolean;
}
/**
 *
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     *
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     *
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     *
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * Модель запроса на восстановление пароля.
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * Код восстановления.
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'code': string;
    /**
     * Новый пароль.
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'newPassword': string;
}
/**
 * Запрос на создание роли.
 * @export
 * @interface RoleCreationRequest
 */
export interface RoleCreationRequest {
    /**
     * Наименование роли.
     * @type {string}
     * @memberof RoleCreationRequest
     */
    'name': string;
    /**
     * Описание роли.
     * @type {string}
     * @memberof RoleCreationRequest
     */
    'description'?: string | null;
}
/**
 * Ответ на создание роли.
 * @export
 * @interface RoleCreationResponse
 */
export interface RoleCreationResponse {
    /**
     * ИД роли.
     * @type {string}
     * @memberof RoleCreationResponse
     */
    'id'?: string | null;
}
/**
 * Модель роли.
 * @export
 * @interface RoleModel
 */
export interface RoleModel {
    /**
     * ИД роли.
     * @type {string}
     * @memberof RoleModel
     */
    'id': string;
    /**
     * Наименование роли.
     * @type {string}
     * @memberof RoleModel
     */
    'name': string;
    /**
     * Описание роли.
     * @type {string}
     * @memberof RoleModel
     */
    'description'?: string | null;
}
/**
 *
 * @export
 * @interface RoleModelPaginationResponse
 */
export interface RoleModelPaginationResponse {
    /**
     *
     * @type {Array<RoleModel>}
     * @memberof RoleModelPaginationResponse
     */
    'items'?: Array<RoleModel> | null;
    /**
     *
     * @type {boolean}
     * @memberof RoleModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     *
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     *
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     *
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     *
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     *
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     *
     * @type {number}
     * @memberof RoleModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * Запрос на обновление роли.
 * @export
 * @interface RoleUpdatingRequest
 */
export interface RoleUpdatingRequest {
    /**
     * Наименование роли.
     * @type {string}
     * @memberof RoleUpdatingRequest
     */
    'name': string;
    /**
     * Описание роли.
     * @type {string}
     * @memberof RoleUpdatingRequest
     */
    'description'?: string | null;
}
/**
 * Модель компании тенанта.
 * @export
 * @interface TenantCompanyModel
 */
export interface TenantCompanyModel {
    /**
     * Наименование компнии.
     * @type {string}
     * @memberof TenantCompanyModel
     */
    'name': string;
}
/**
 * Запрос на создание тенанта.
 * @export
 * @interface TenantCreationRequest
 */
export interface TenantCreationRequest {
    /**
     * Наименование тенанта.
     * @type {string}
     * @memberof TenantCreationRequest
     */
    'name': string;
    /**
     * Описание тенанта.
     * @type {string}
     * @memberof TenantCreationRequest
     */
    'description'?: string | null;
    /**
     *
     * @type {TenantCompanyModel}
     * @memberof TenantCreationRequest
     */
    'company': TenantCompanyModel;
    /**
     *
     * @type {TenantTariffModel}
     * @memberof TenantCreationRequest
     */
    'tariff': TenantTariffModel;
    /**
     * Информация о связанных тенантах из внешних поставщиков.
     * @type {Array<TenantExternalInfoModel>}
     * @memberof TenantCreationRequest
     */
    'externals': Array<TenantExternalInfoModel>;
}
/**
 * Ответ на создание тенанта.
 * @export
 * @interface TenantCreationResponse
 */
export interface TenantCreationResponse {
    /**
     * ИД тенанта.
     * @type {string}
     * @memberof TenantCreationResponse
     */
    'id': string;
}
/**
 * Модель информации о тенанте из внешнего поставщика.
 * @export
 * @interface TenantExternalInfoModel
 */
export interface TenantExternalInfoModel {
    /**
     * ИД внешнего поставщика.
     * @type {string}
     * @memberof TenantExternalInfoModel
     */
    'providerId': string;
    /**
     * ИД тенанта в поставщике.
     * @type {string}
     * @memberof TenantExternalInfoModel
     */
    'tenantId': string;
}
/**
 * Модель тенанта.
 * @export
 * @interface TenantModel
 */
export interface TenantModel {
    /**
     * ИД тенанта.
     * @type {string}
     * @memberof TenantModel
     */
    'id': string;
    /**
     * Наименование тенанта.
     * @type {string}
     * @memberof TenantModel
     */
    'name': string;
    /**
     * Описание тенанта.
     * @type {string}
     * @memberof TenantModel
     */
    'description'?: string | null;
    /**
     *
     * @type {TenantCompanyModel}
     * @memberof TenantModel
     */
    'company': TenantCompanyModel;
    /**
     *
     * @type {TenantTariffModel}
     * @memberof TenantModel
     */
    'tariff': TenantTariffModel;
    /**
     * Токен доступа.
     * @type {string}
     * @memberof TenantModel
     */
    'xToken': string;
    /**
     * Информация о связанных тенантах из внешних поставщиков.
     * @type {Array<TenantExternalInfoModel>}
     * @memberof TenantModel
     */
    'externals': Array<TenantExternalInfoModel>;
}
/**
 *
 * @export
 * @interface TenantModelPaginationResponse
 */
export interface TenantModelPaginationResponse {
    /**
     *
     * @type {Array<TenantModel>}
     * @memberof TenantModelPaginationResponse
     */
    'items'?: Array<TenantModel> | null;
    /**
     *
     * @type {boolean}
     * @memberof TenantModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     *
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     *
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     *
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     *
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     *
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     *
     * @type {number}
     * @memberof TenantModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 * Модель тарифа тенанта.
 * @export
 * @interface TenantTariffModel
 */
export interface TenantTariffModel {
    /**
     * Код тарифа.
     * @type {string}
     * @memberof TenantTariffModel
     */
    'code': string;
}
/**
 * Запрос на обновление тенанта.
 * @export
 * @interface TenantUpdatingRequest
 */
export interface TenantUpdatingRequest {
    /**
     * Наименование тенанта.
     * @type {string}
     * @memberof TenantUpdatingRequest
     */
    'name': string;
    /**
     * Описание тенанта.
     * @type {string}
     * @memberof TenantUpdatingRequest
     */
    'description'?: string | null;
    /**
     *
     * @type {TenantCompanyModel}
     * @memberof TenantUpdatingRequest
     */
    'company': TenantCompanyModel;
    /**
     *
     * @type {TenantTariffModel}
     * @memberof TenantUpdatingRequest
     */
    'tariff': TenantTariffModel;
    /**
     * Информация о связанных тенантах из внешних поставщиков.
     * @type {Array<TenantExternalInfoModel>}
     * @memberof TenantUpdatingRequest
     */
    'externals': Array<TenantExternalInfoModel>;
}
/**
 *
 * @export
 * @interface TrialAcceptionRequest
 */
export interface TrialAcceptionRequest {
    /**
     *
     * @type {string}
     * @memberof TrialAcceptionRequest
     */
    'code': string;
    /**
     *
     * @type {string}
     * @memberof TrialAcceptionRequest
     */
    'password': string;
}
/**
 *
 * @export
 * @interface TrialConfirmationResponse
 */
export interface TrialConfirmationResponse {
    /**
     *
     * @type {string}
     * @memberof TrialConfirmationResponse
     */
    'resetPasswordToken': string;
    /**
     *
     * @type {string}
     * @memberof TrialConfirmationResponse
     */
    'userId': string;
    /**
     *
     * @type {string}
     * @memberof TrialConfirmationResponse
     */
    'trialId': string;
}
/**
 *
 * @export
 * @interface TrialCreationRequest
 */
export interface TrialCreationRequest {
    /**
     *
     * @type {string}
     * @memberof TrialCreationRequest
     */
    'email': string;
    /**
     *
     * @type {string}
     * @memberof TrialCreationRequest
     */
    'companyName': string;
    /**
     *
     * @type {string}
     * @memberof TrialCreationRequest
     */
    'familyName': string;
    /**
     *
     * @type {string}
     * @memberof TrialCreationRequest
     */
    'givenName': string;
    /**
     *
     * @type {string}
     * @memberof TrialCreationRequest
     */
    'middleName'?: string | null;
    /**
     *
     * @type {string}
     * @memberof TrialCreationRequest
     */
    'phoneNumber': string;
}
/**
 *
 * @export
 * @interface TrialCreationResponse
 */
export interface TrialCreationResponse {
    /**
     *
     * @type {boolean}
     * @memberof TrialCreationResponse
     */
    'isNew': boolean;
    /**
     *
     * @type {string}
     * @memberof TrialCreationResponse
     */
    'id': string;
}
/**
 *
 * @export
 * @interface TrialExtendRequest
 */
export interface TrialExtendRequest {
    /**
     *
     * @type {string}
     * @memberof TrialExtendRequest
     */
    'expiresOn': string;
}
/**
 * Модель триала.
 * @export
 * @interface TrialModel
 */
export interface TrialModel {
    /**
     * ИД триала.
     * @type {string}
     * @memberof TrialModel
     */
    'id': string;
    /**
     * ИД тенанта, привязанного к триалу.
     * @type {string}
     * @memberof TrialModel
     */
    'tenantId'?: string | null;
    /**
     * ИД пользователя, привязанного к триалу.
     * @type {string}
     * @memberof TrialModel
     */
    'userId'?: string | null;
    /**
     * ФИО пользователя.
     * @type {string}
     * @memberof TrialModel
     */
    'fullName': string;
    /**
     * Адрес электронной почты.
     * @type {string}
     * @memberof TrialModel
     */
    'email': string;
    /**
     * Название компании.
     * @type {string}
     * @memberof TrialModel
     */
    'companyName': string;
    /**
     * Номер телефона.
     * @type {string}
     * @memberof TrialModel
     */
    'phoneNumber': string;
    /**
     * Дата создания.
     * @type {string}
     * @memberof TrialModel
     */
    'createdOn': string;
    /**
     * Дата окончания.
     * @type {string}
     * @memberof TrialModel
     */
    'expiresOn'?: string | null;
    /**
     *
     * @type {TrialStatus}
     * @memberof TrialModel
     */
    'status': TrialStatus;
}


/**
 *
 * @export
 * @interface TrialModelPaginationResponse
 */
export interface TrialModelPaginationResponse {
    /**
     *
     * @type {Array<TrialModel>}
     * @memberof TrialModelPaginationResponse
     */
    'items'?: Array<TrialModel> | null;
    /**
     *
     * @type {boolean}
     * @memberof TrialModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     *
     * @type {number}
     * @memberof TrialModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     *
     * @type {number}
     * @memberof TrialModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     *
     * @type {number}
     * @memberof TrialModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     *
     * @type {number}
     * @memberof TrialModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     *
     * @type {number}
     * @memberof TrialModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     *
     * @type {number}
     * @memberof TrialModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TrialStatus = {
    Requested: 'Requested',
    Active: 'Active',
    Expired: 'Expired',
    Cancelled: 'Cancelled'
} as const;

export type TrialStatus = typeof TrialStatus[keyof typeof TrialStatus];


/**
 * Запрос на создание пользователя.
 * @export
 * @interface UserCreationRequest
 */
export interface UserCreationRequest {
    /**
     * Логин пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    'login': string;
    /**
     * Пароль пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    'password': string;
    /**
     * Email пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    'email': string;
    /**
     * Номер телефона пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    'phoneNumber': string;
    /**
     * Полное имя пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    'fullName': string;
    /**
     * Краткое имя пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    'shortName': string;
    /**
     * Фамилия пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    'familyName': string;
    /**
     * Имя пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    'givenName': string;
    /**
     * Отчество пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    'middleName'?: string | null;
    /**
     * Наименование тенантов пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    'tenantNames'?: string | null;
    /**
     * Наименования ролей пользователя.
     * @type {string}
     * @memberof UserCreationRequest
     */
    'roleNames'?: string | null;
    /**
     * Информация о связанных пользователях из внешних поставщиков.
     * @type {Array<UserLoginInfoModel>}
     * @memberof UserCreationRequest
     */
    'logins'?: Array<UserLoginInfoModel> | null;
}
/**
 * Ответ на создание пользователя.
 * @export
 * @interface UserCreationResponse
 */
export interface UserCreationResponse {
    /**
     * ИД пользователя.
     * @type {string}
     * @memberof UserCreationResponse
     */
    'id'?: string | null;
}
/**
 * Запрос на приглашение пользователя.
 * @export
 * @interface UserInvitationRequest
 */
export interface UserInvitationRequest {
    /**
     * Логин пользователя.
     * @type {string}
     * @memberof UserInvitationRequest
     */
    'login': string;
    /**
     * Email пользователя.
     * @type {string}
     * @memberof UserInvitationRequest
     */
    'email': string;
    /**
     * Номер телефона пользователя.
     * @type {string}
     * @memberof UserInvitationRequest
     */
    'phoneNumber'?: string | null;
    /**
     * Полное имя пользователя.
     * @type {string}
     * @memberof UserInvitationRequest
     */
    'fullName': string;
    /**
     * Краткое имя пользователя.
     * @type {string}
     * @memberof UserInvitationRequest
     */
    'shortName'?: string | null;
    /**
     * Фамилия пользователя.
     * @type {string}
     * @memberof UserInvitationRequest
     */
    'familyName'?: string | null;
    /**
     * Имя пользователя.
     * @type {string}
     * @memberof UserInvitationRequest
     */
    'givenName'?: string | null;
    /**
     * Отчество пользователя.
     * @type {string}
     * @memberof UserInvitationRequest
     */
    'middleName'?: string | null;
    /**
     * Наименование тенантов пользователя.
     * @type {string}
     * @memberof UserInvitationRequest
     */
    'tenantNames'?: string | null;
    /**
     * Наименования ролей пользователя.
     * @type {string}
     * @memberof UserInvitationRequest
     */
    'roleNames'?: string | null;
}
/**
 * Результат отправки приглашения.
 * @export
 * @interface UserInvitationResponse
 */
export interface UserInvitationResponse {
    /**
     * true - успешная отправка, false - не успешная.
     * @type {boolean}
     * @memberof UserInvitationResponse
     */
    'success': boolean;
    /**
     * Сообщение об ошибке при не успешной отправке.
     * @type {string}
     * @memberof UserInvitationResponse
     */
    'errorMessage'?: string | null;
}
/**
 * Результат отправки приглашения.
 * @export
 * @interface UserInvitationResult
 */
export interface UserInvitationResult {
    /**
     *
     * @type {boolean}
     * @memberof UserInvitationResult
     */
    'success'?: boolean;
    /**
     *
     * @type {string}
     * @memberof UserInvitationResult
     */
    'errorMessage'?: string | null;
}
/**
 * Модель информации о пользователе из внешнего поставщика.
 * @export
 * @interface UserLoginInfoModel
 */
export interface UserLoginInfoModel {
    /**
     * ИД внешнего поставщика.
     * @type {string}
     * @memberof UserLoginInfoModel
     */
    'loginProvider': string;
    /**
     * ИД пользователя в поставщике.
     * @type {string}
     * @memberof UserLoginInfoModel
     */
    'providerKey': string;
    /**
     * Отображаемое имя пользователя в поставщике.
     * @type {string}
     * @memberof UserLoginInfoModel
     */
    'providerDisplayName'?: string | null;
}
/**
 * Модель пользователя.
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * ИД пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'id': string;
    /**
     * Логин пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'login': string;
    /**
     * Пароль пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'password': string;
    /**
     * Email пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'email': string;
    /**
     * Номер телефона пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'phoneNumber': string;
    /**
     * Полное имя пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'fullName': string;
    /**
     * Краткое имя пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'shortName': string;
    /**
     * Фамилия пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'familyName': string;
    /**
     * Имя пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'givenName': string;
    /**
     * Отчество пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'middleName'?: string | null;
    /**
     * Наименование тенантов пользователя.
     * @type {string}
     * @memberof UserModel
     */
    'tenantNames': string;
    /**
     * Роли пользователя.
     * @type {Array<RoleModel>}
     * @memberof UserModel
     */
    'roles': Array<RoleModel>;
    /**
     * Информация о связанных пользователях из внешних поставщиков.
     * @type {Array<UserLoginInfoModel>}
     * @memberof UserModel
     */
    'logins': Array<UserLoginInfoModel>;
    /**
     * Дата создания.
     * @type {string}
     * @memberof UserModel
     */
    'createdOn': string;
    /**
     *
     * @type {UserStatus}
     * @memberof UserModel
     */
    'status': UserStatus;
    /**
     *
     * @type {UserInvitationResult}
     * @memberof UserModel
     */
    'invitationResult'?: UserInvitationResult;
}


/**
 *
 * @export
 * @interface UserModelPaginationResponse
 */
export interface UserModelPaginationResponse {
    /**
     *
     * @type {Array<UserModel>}
     * @memberof UserModelPaginationResponse
     */
    'items'?: Array<UserModel> | null;
    /**
     *
     * @type {boolean}
     * @memberof UserModelPaginationResponse
     */
    'hasMore'?: boolean;
    /**
     *
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    'pageIndex'?: number;
    /**
     *
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    'pageSize'?: number;
    /**
     *
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    'totalItemCount'?: number | null;
    /**
     *
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    'totalPageCount'?: number | null;
    /**
     *
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    'fromItemInclusive'?: number;
    /**
     *
     * @type {number}
     * @memberof UserModelPaginationResponse
     */
    'toItemExclusive'?: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const UserStatus = {
    Invited: 'Invited',
    Active: 'Active',
    Blocked: 'Blocked'
} as const;

export type UserStatus = typeof UserStatus[keyof typeof UserStatus];


/**
 * Запрос на обновление пользователя.
 * @export
 * @interface UserUpdatingRequest
 */
export interface UserUpdatingRequest {
    /**
     * Логин пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'login': string;
    /**
     * Email пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'email': string;
    /**
     * Номер телефона пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'phoneNumber'?: string | null;
    /**
     * Полное имя пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'fullName': string;
    /**
     * Краткое имя пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'shortName'?: string | null;
    /**
     * Фамилия пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'familyName'?: string | null;
    /**
     * Имя пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'givenName'?: string | null;
    /**
     * Отчество пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'middleName'?: string | null;
    /**
     * Наименование тенантов пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'tenantNames'?: string | null;
    /**
     * Наименования ролей пользователя.
     * @type {string}
     * @memberof UserUpdatingRequest
     */
    'roleNames'?: string | null;
    /**
     * Информация о связанных пользователях из внешних поставщиков.
     * @type {Array<UserLoginInfoModel>}
     * @memberof UserUpdatingRequest
     */
    'logins'?: Array<UserLoginInfoModel> | null;
}
/**
 *
 * @export
 * @interface ValidationProblemDetails
 */
export interface ValidationProblemDetails {
    [key: string]: any;

    /**
     *
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ValidationProblemDetails
     */
    'errors'?: { [key: string]: Array<string>; } | null;
    /**
     *
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'type'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'title'?: string | null;
    /**
     *
     * @type {number}
     * @memberof ValidationProblemDetails
     */
    'status'?: number | null;
    /**
     *
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'detail'?: string | null;
    /**
     *
     * @type {string}
     * @memberof ValidationProblemDetails
     */
    'instance'?: string | null;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Получить информацию об аккаунте.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/identity/account/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Получить информацию об ошибке.
         * @param {string} [errorId] ИД выхоошибкида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getErrorInfo: async (errorId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/identity/account/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (errorId !== undefined) {
                localVarQueryParameter['errorId'] = errorId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Получить информацию о выходе.
         * @param {string} [logoutId] ИД выхода.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogoutInfo: async (logoutId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/identity/account/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (logoutId !== undefined) {
                localVarQueryParameter['logoutId'] = logoutId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Выполнить вход.
         * @param {LoginRequestModel} [loginRequestModel] Запрос на вход.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAccountLogin: async (loginRequestModel?: LoginRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/identity/account/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Выполнить выход.
         * @param {LogoutRequestModel} [logoutRequestModel] Запрос на выход.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAccountLogout: async (logoutRequestModel?: LogoutRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/identity/account/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logoutRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Получить информацию об аккаунте.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountInfoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountInfo(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.getAccountInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Получить информацию об ошибке.
         * @param {string} [errorId] ИД выхоошибкида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getErrorInfo(errorId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ErrorInfoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getErrorInfo(errorId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.getErrorInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Получить информацию о выходе.
         * @param {string} [logoutId] ИД выхода.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogoutInfo(logoutId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogoutInfoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogoutInfo(logoutId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.getLogoutInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Выполнить вход.
         * @param {LoginRequestModel} [loginRequestModel] Запрос на вход.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processAccountLogin(loginRequestModel?: LoginRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processAccountLogin(loginRequestModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.processAccountLogin']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Выполнить выход.
         * @param {LogoutRequestModel} [logoutRequestModel] Запрос на выход.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processAccountLogout(logoutRequestModel?: LogoutRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogoutResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processAccountLogout(logoutRequestModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.processAccountLogout']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         *
         * @summary Получить информацию об аккаунте.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountInfo(options?: any): AxiosPromise<AccountInfoModel> {
            return localVarFp.getAccountInfo(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Получить информацию об ошибке.
         * @param {string} [errorId] ИД выхоошибкида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getErrorInfo(errorId?: string, options?: any): AxiosPromise<ErrorInfoModel> {
            return localVarFp.getErrorInfo(errorId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Получить информацию о выходе.
         * @param {string} [logoutId] ИД выхода.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogoutInfo(logoutId?: string, options?: any): AxiosPromise<LogoutInfoModel> {
            return localVarFp.getLogoutInfo(logoutId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Выполнить вход.
         * @param {LoginRequestModel} [loginRequestModel] Запрос на вход.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAccountLogin(loginRequestModel?: LoginRequestModel, options?: any): AxiosPromise<LoginResponseModel> {
            return localVarFp.processAccountLogin(loginRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Выполнить выход.
         * @param {LogoutRequestModel} [logoutRequestModel] Запрос на выход.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAccountLogout(logoutRequestModel?: LogoutRequestModel, options?: any): AxiosPromise<LogoutResponseModel> {
            return localVarFp.processAccountLogout(logoutRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     *
     * @summary Получить информацию об аккаунте.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccountInfo(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getAccountInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Получить информацию об ошибке.
     * @param {string} [errorId] ИД выхоошибкида.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getErrorInfo(errorId?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getErrorInfo(errorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Получить информацию о выходе.
     * @param {string} [logoutId] ИД выхода.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getLogoutInfo(logoutId?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getLogoutInfo(logoutId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Выполнить вход.
     * @param {LoginRequestModel} [loginRequestModel] Запрос на вход.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public processAccountLogin(loginRequestModel?: LoginRequestModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).processAccountLogin(loginRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Выполнить выход.
     * @param {LogoutRequestModel} [logoutRequestModel] Запрос на выход.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public processAccountLogout(logoutRequestModel?: LogoutRequestModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).processAccountLogout(logoutRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAboutInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/application/about`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/application/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAboutInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AboutModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAboutInfo(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ApplicationApi.getAboutInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealthInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealthInfo(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ApplicationApi.getHealthInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationApiFp(configuration)
    return {
        /**
         *
         * @summary Получить информацию о приложении.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAboutInfo(options?: any): AxiosPromise<AboutModel> {
            return localVarFp.getAboutInfo(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Получить информацию о работоспособности.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealthInfo(options?: any): AxiosPromise<HealthModel> {
            return localVarFp.getHealthInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
    /**
     *
     * @summary Получить информацию о приложении.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getAboutInfo(options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).getAboutInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Получить информацию о работоспособности.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getHealthInfo(options?: AxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).getHealthInfo(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
export const ConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConfig: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/info/configuration/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationApi - functional programming interface
 * @export
 */
export const ConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientConfig(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientConfig(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConfigurationApi.getClientConfig']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ConfigurationApi - factory interface
 * @export
 */
export const ConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationApiFp(configuration)
    return {
        /**
         *
         * @summary Получить конфигурацию клиента.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConfig(options?: any): AxiosPromise<void> {
            return localVarFp.getClientConfig(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
export class ConfigurationApi extends BaseAPI {
    /**
     *
     * @summary Получить конфигурацию клиента.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getClientConfig(options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).getClientConfig(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Получить событие.
         * @param {string} id ИД события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEvent', 'id', id)
            const localVarPath = `/api/v1/general/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Выполнить поиск событий.
         * @param {string} [type] Тип события.
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEvents: async (type?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/general/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Получить событие.
         * @param {string} id ИД события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvent(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvent(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EventApi.getEvent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Выполнить поиск событий.
         * @param {string} [type] Тип события.
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchEvents(type?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchEvents(type, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EventApi.searchEvents']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventApiFp(configuration)
    return {
        /**
         *
         * @summary Получить событие.
         * @param {string} id ИД события.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent(id: string, options?: any): AxiosPromise<EventModel> {
            return localVarFp.getEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Выполнить поиск событий.
         * @param {string} [type] Тип события.
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEvents(type?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<EventModelPaginationResponse> {
            return localVarFp.searchEvents(type, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
    /**
     *
     * @summary Получить событие.
     * @param {string} id ИД события.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public getEvent(id: string, options?: AxiosRequestConfig) {
        return EventApiFp(this.configuration).getEvent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Выполнить поиск событий.
     * @param {string} [type] Тип события.
     * @param {number} [pageIndex]
     * @param {number} [pageSize]
     * @param {boolean} [withTotal]
     * @param {number} [fromItemInclusive]
     * @param {number} [toItemExclusive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public searchEvents(type?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: AxiosRequestConfig) {
        return EventApiFp(this.configuration).searchEvents(type, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PasswordApi - axios parameter creator
 * @export
 */
export const PasswordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Отправка ссылки восстановления пароля пользователя.
         * @param {ForgotPasswordRequest} [forgotPasswordRequest] Запрос на отправку ссылки восстановления пароля.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotUserPassword: async (forgotPasswordRequest?: ForgotPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/membership/password/forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Получить требования к паролю.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPasswordOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/membership/password/options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordApi - functional programming interface
 * @export
 */
export const PasswordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Отправка ссылки восстановления пароля пользователя.
         * @param {ForgotPasswordRequest} [forgotPasswordRequest] Запрос на отправку ссылки восстановления пароля.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotUserPassword(forgotPasswordRequest?: ForgotPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotUserPassword(forgotPasswordRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PasswordApi.forgotUserPassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Получить требования к паролю.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPasswordOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordOptionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPasswordOptions(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PasswordApi.getPasswordOptions']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PasswordApi - factory interface
 * @export
 */
export const PasswordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordApiFp(configuration)
    return {
        /**
         *
         * @summary Отправка ссылки восстановления пароля пользователя.
         * @param {ForgotPasswordRequest} [forgotPasswordRequest] Запрос на отправку ссылки восстановления пароля.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotUserPassword(forgotPasswordRequest?: ForgotPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.forgotUserPassword(forgotPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Получить требования к паролю.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPasswordOptions(options?: any): AxiosPromise<PasswordOptionsResponse> {
            return localVarFp.getPasswordOptions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordApi - object-oriented interface
 * @export
 * @class PasswordApi
 * @extends {BaseAPI}
 */
export class PasswordApi extends BaseAPI {
    /**
     *
     * @summary Отправка ссылки восстановления пароля пользователя.
     * @param {ForgotPasswordRequest} [forgotPasswordRequest] Запрос на отправку ссылки восстановления пароля.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public forgotUserPassword(forgotPasswordRequest?: ForgotPasswordRequest, options?: AxiosRequestConfig) {
        return PasswordApiFp(this.configuration).forgotUserPassword(forgotPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Получить требования к паролю.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public getPasswordOptions(options?: AxiosRequestConfig) {
        return PasswordApiFp(this.configuration).getPasswordOptions(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProviderApi - axios parameter creator
 * @export
 */
export const ProviderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Инициировать вход через поставщик аутентификации.
         * @param {string} [scheme] Схема аутентификации.
         * @param {string} [digest] Дайджест удостоверения.
         * @param {string} [returnUrl] URL возврата.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        challengeProviderLogin: async (scheme?: string, digest?: string, returnUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/identity/providers/challenge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (scheme !== undefined) {
                localVarQueryParameter['scheme'] = scheme;
            }

            if (digest !== undefined) {
                localVarQueryParameter['digest'] = digest;
            }

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Обработать обратный вызов от поставщика аутентификации.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processProviderCallback: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/identity/providers/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [scheme]
         * @param {string} [logoutId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processProviderLogout: async (scheme?: string, logoutId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/identity/providers/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (scheme !== undefined) {
                localVarQueryParameter['scheme'] = scheme;
            }

            if (logoutId !== undefined) {
                localVarQueryParameter['logoutId'] = logoutId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderApi - functional programming interface
 * @export
 */
export const ProviderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Инициировать вход через поставщик аутентификации.
         * @param {string} [scheme] Схема аутентификации.
         * @param {string} [digest] Дайджест удостоверения.
         * @param {string} [returnUrl] URL возврата.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async challengeProviderLogin(scheme?: string, digest?: string, returnUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.challengeProviderLogin(scheme, digest, returnUrl, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.challengeProviderLogin']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Обработать обратный вызов от поставщика аутентификации.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processProviderCallback(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processProviderCallback(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.processProviderCallback']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} [scheme]
         * @param {string} [logoutId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processProviderLogout(scheme?: string, logoutId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processProviderLogout(scheme, logoutId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProviderApi.processProviderLogout']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ProviderApi - factory interface
 * @export
 */
export const ProviderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderApiFp(configuration)
    return {
        /**
         *
         * @summary Инициировать вход через поставщик аутентификации.
         * @param {string} [scheme] Схема аутентификации.
         * @param {string} [digest] Дайджест удостоверения.
         * @param {string} [returnUrl] URL возврата.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        challengeProviderLogin(scheme?: string, digest?: string, returnUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.challengeProviderLogin(scheme, digest, returnUrl, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Обработать обратный вызов от поставщика аутентификации.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processProviderCallback(options?: any): AxiosPromise<void> {
            return localVarFp.processProviderCallback(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} [scheme]
         * @param {string} [logoutId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processProviderLogout(scheme?: string, logoutId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.processProviderLogout(scheme, logoutId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProviderApi - object-oriented interface
 * @export
 * @class ProviderApi
 * @extends {BaseAPI}
 */
export class ProviderApi extends BaseAPI {
    /**
     *
     * @summary Инициировать вход через поставщик аутентификации.
     * @param {string} [scheme] Схема аутентификации.
     * @param {string} [digest] Дайджест удостоверения.
     * @param {string} [returnUrl] URL возврата.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public challengeProviderLogin(scheme?: string, digest?: string, returnUrl?: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).challengeProviderLogin(scheme, digest, returnUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Обработать обратный вызов от поставщика аутентификации.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public processProviderCallback(options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).processProviderCallback(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} [scheme]
     * @param {string} [logoutId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderApi
     */
    public processProviderLogout(scheme?: string, logoutId?: string, options?: AxiosRequestConfig) {
        return ProviderApiFp(this.configuration).processProviderLogout(scheme, logoutId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Создать роль.
         * @param {RoleCreationRequest} [roleCreationRequest] Запрос на создание роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole: async (roleCreationRequest?: RoleCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/membership/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Удалить роль.
         * @param {string} id ИД роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRole', 'id', id)
            const localVarPath = `/api/v1/membership/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Получить роль.
         * @param {string} id ИД роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRole', 'id', id)
            const localVarPath = `/api/v1/membership/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Выполнить поиск ролей.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRoles: async (filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/membership/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filtration !== undefined) {
                for (const [key, value] of Object.entries(filtration)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Обновить роль.
         * @param {string} id ИД роли.
         * @param {RoleUpdatingRequest} [roleUpdatingRequest] Запрос на обновление роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole: async (id: string, roleUpdatingRequest?: RoleUpdatingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRole', 'id', id)
            const localVarPath = `/api/v1/membership/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Создать роль.
         * @param {RoleCreationRequest} [roleCreationRequest] Запрос на создание роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRole(roleCreationRequest?: RoleCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRole(roleCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoleApi.createRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Удалить роль.
         * @param {string} id ИД роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRole(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRole(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoleApi.deleteRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Получить роль.
         * @param {string} id ИД роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRole(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRole(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoleApi.getRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Выполнить поиск ролей.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchRoles(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchRoles(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoleApi.searchRoles']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Обновить роль.
         * @param {string} id ИД роли.
         * @param {RoleUpdatingRequest} [roleUpdatingRequest] Запрос на обновление роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRole(id: string, roleUpdatingRequest?: RoleUpdatingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRole(id, roleUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoleApi.updateRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleApiFp(configuration)
    return {
        /**
         *
         * @summary Создать роль.
         * @param {RoleCreationRequest} [roleCreationRequest] Запрос на создание роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRole(roleCreationRequest?: RoleCreationRequest, options?: any): AxiosPromise<RoleCreationResponse> {
            return localVarFp.createRole(roleCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Удалить роль.
         * @param {string} id ИД роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRole(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRole(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Получить роль.
         * @param {string} id ИД роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRole(id: string, options?: any): AxiosPromise<RoleModel> {
            return localVarFp.getRole(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Выполнить поиск ролей.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRoles(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<RoleModelPaginationResponse> {
            return localVarFp.searchRoles(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Обновить роль.
         * @param {string} id ИД роли.
         * @param {RoleUpdatingRequest} [roleUpdatingRequest] Запрос на обновление роли.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRole(id: string, roleUpdatingRequest?: RoleUpdatingRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateRole(id, roleUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI {
    /**
     *
     * @summary Создать роль.
     * @param {RoleCreationRequest} [roleCreationRequest] Запрос на создание роли.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public createRole(roleCreationRequest?: RoleCreationRequest, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).createRole(roleCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Удалить роль.
     * @param {string} id ИД роли.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public deleteRole(id: string, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).deleteRole(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Получить роль.
     * @param {string} id ИД роли.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public getRole(id: string, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).getRole(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Выполнить поиск ролей.
     * @param {object} [filtration] Параметры фильтрации.
     * @param {number} [pageIndex]
     * @param {number} [pageSize]
     * @param {boolean} [withTotal]
     * @param {number} [fromItemInclusive]
     * @param {number} [toItemExclusive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public searchRoles(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).searchRoles(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Обновить роль.
     * @param {string} id ИД роли.
     * @param {RoleUpdatingRequest} [roleUpdatingRequest] Запрос на обновление роли.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public updateRole(id: string, roleUpdatingRequest?: RoleUpdatingRequest, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).updateRole(id, roleUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TenantApi - axios parameter creator
 * @export
 */
export const TenantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Создать тенанта.
         * @param {TenantCreationRequest} [tenantCreationRequest] Запрос на создание тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant: async (tenantCreationRequest?: TenantCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/general/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Удалить тенант.
         * @param {string} id ИД тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenant: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTenant', 'id', id)
            const localVarPath = `/api/v1/general/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Получить текущий тенант авторизованного пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTenant: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/general/tenants/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Получить тенант.
         * @param {string} id ИД тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenant: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTenant', 'id', id)
            const localVarPath = `/api/v1/general/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Выполнить поиск тенантов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTenants: async (filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/general/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filtration !== undefined) {
                for (const [key, value] of Object.entries(filtration)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Обновить тенанта.
         * @param {string} id ИД тенанта.
         * @param {TenantUpdatingRequest} [tenantUpdatingRequest] Запрос на обновление тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenant: async (id: string, tenantUpdatingRequest?: TenantUpdatingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTenant', 'id', id)
            const localVarPath = `/api/v1/general/tenants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantApi - functional programming interface
 * @export
 */
export const TenantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Создать тенанта.
         * @param {TenantCreationRequest} [tenantCreationRequest] Запрос на создание тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTenant(tenantCreationRequest?: TenantCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTenant(tenantCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TenantApi.createTenant']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Удалить тенант.
         * @param {string} id ИД тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTenant(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTenant(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TenantApi.deleteTenant']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Получить текущий тенант авторизованного пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentTenant(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentTenant(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TenantApi.getCurrentTenant']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Получить тенант.
         * @param {string} id ИД тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenant(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenant(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TenantApi.getTenant']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Выполнить поиск тенантов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTenants(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTenants(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TenantApi.searchTenants']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Обновить тенанта.
         * @param {string} id ИД тенанта.
         * @param {TenantUpdatingRequest} [tenantUpdatingRequest] Запрос на обновление тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTenant(id: string, tenantUpdatingRequest?: TenantUpdatingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTenant(id, tenantUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TenantApi.updateTenant']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TenantApi - factory interface
 * @export
 */
export const TenantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantApiFp(configuration)
    return {
        /**
         *
         * @summary Создать тенанта.
         * @param {TenantCreationRequest} [tenantCreationRequest] Запрос на создание тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTenant(tenantCreationRequest?: TenantCreationRequest, options?: any): AxiosPromise<TenantCreationResponse> {
            return localVarFp.createTenant(tenantCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Удалить тенант.
         * @param {string} id ИД тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTenant(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTenant(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Получить текущий тенант авторизованного пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentTenant(options?: any): AxiosPromise<TenantModel> {
            return localVarFp.getCurrentTenant(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Получить тенант.
         * @param {string} id ИД тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenant(id: string, options?: any): AxiosPromise<TenantModel> {
            return localVarFp.getTenant(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Выполнить поиск тенантов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTenants(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<TenantModelPaginationResponse> {
            return localVarFp.searchTenants(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Обновить тенанта.
         * @param {string} id ИД тенанта.
         * @param {TenantUpdatingRequest} [tenantUpdatingRequest] Запрос на обновление тенанта.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTenant(id: string, tenantUpdatingRequest?: TenantUpdatingRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateTenant(id, tenantUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenantApi - object-oriented interface
 * @export
 * @class TenantApi
 * @extends {BaseAPI}
 */
export class TenantApi extends BaseAPI {
    /**
     *
     * @summary Создать тенанта.
     * @param {TenantCreationRequest} [tenantCreationRequest] Запрос на создание тенанта.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public createTenant(tenantCreationRequest?: TenantCreationRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).createTenant(tenantCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Удалить тенант.
     * @param {string} id ИД тенанта.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public deleteTenant(id: string, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).deleteTenant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Получить текущий тенант авторизованного пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getCurrentTenant(options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getCurrentTenant(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Получить тенант.
     * @param {string} id ИД тенанта.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public getTenant(id: string, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).getTenant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Выполнить поиск тенантов.
     * @param {object} [filtration] Параметры фильтрации.
     * @param {number} [pageIndex]
     * @param {number} [pageSize]
     * @param {boolean} [withTotal]
     * @param {number} [fromItemInclusive]
     * @param {number} [toItemExclusive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public searchTenants(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).searchTenants(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Обновить тенанта.
     * @param {string} id ИД тенанта.
     * @param {TenantUpdatingRequest} [tenantUpdatingRequest] Запрос на обновление тенанта.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantApi
     */
    public updateTenant(id: string, tenantUpdatingRequest?: TenantUpdatingRequest, options?: AxiosRequestConfig) {
        return TenantApiFp(this.configuration).updateTenant(id, tenantUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TrialApi - axios parameter creator
 * @export
 */
export const TrialApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Произвести активацию триала.
         * @param {string} [trialId] ИД триала.
         * @param {string} [userId] ИД пользователя.
         * @param {TrialAcceptionRequest} [trialAcceptionRequest] Запрос на активацию триала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateTrial: async (trialId?: string, userId?: string, trialAcceptionRequest?: TrialAcceptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/licence/trials/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (trialId !== undefined) {
                localVarQueryParameter['trialId'] = trialId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trialAcceptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Деактивировать триал.
         * @param {string} id ИД триала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTrial: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelTrial', 'id', id)
            const localVarPath = `/api/v1/licence/trials/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Подтвердить активацию триала.
         * @param {string} verificationToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmTrial: async (verificationToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verificationToken' is not null or undefined
            assertParamExists('confirmTrial', 'verificationToken', verificationToken)
            const localVarPath = `/api/v1/licence/trials/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (verificationToken !== undefined) {
                localVarQueryParameter['VerificationToken'] = verificationToken;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Установить новую дату окончания триала.
         * @param {string} id ИД триала.
         * @param {TrialExtendRequest} [trialExtendRequest] Новая дата окончания триала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendTrial: async (id: string, trialExtendRequest?: TrialExtendRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('extendTrial', 'id', id)
            const localVarPath = `/api/v1/licence/trials/{id}/extend`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trialExtendRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Получить информацию о триале.
         * @param {string} id ИД триала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrial: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTrial', 'id', id)
            const localVarPath = `/api/v1/licence/trials/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Отправить запрос на триал.
         * @param {TrialCreationRequest} [trialCreationRequest] Параметры запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestTrial: async (trialCreationRequest?: TrialCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/licence/trials/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trialCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Выполнить поиск триалов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTrials: async (filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/licence/trials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filtration !== undefined) {
                for (const [key, value] of Object.entries(filtration)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendEmail', 'id', id)
            const localVarPath = `/api/v1/licence/trials/{id}/send`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrialApi - functional programming interface
 * @export
 */
export const TrialApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrialApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Произвести активацию триала.
         * @param {string} [trialId] ИД триала.
         * @param {string} [userId] ИД пользователя.
         * @param {TrialAcceptionRequest} [trialAcceptionRequest] Запрос на активацию триала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateTrial(trialId?: string, userId?: string, trialAcceptionRequest?: TrialAcceptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateTrial(trialId, userId, trialAcceptionRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TrialApi.activateTrial']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Деактивировать триал.
         * @param {string} id ИД триала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelTrial(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelTrial(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TrialApi.cancelTrial']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Подтвердить активацию триала.
         * @param {string} verificationToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmTrial(verificationToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialConfirmationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmTrial(verificationToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TrialApi.confirmTrial']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Установить новую дату окончания триала.
         * @param {string} id ИД триала.
         * @param {TrialExtendRequest} [trialExtendRequest] Новая дата окончания триала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extendTrial(id: string, trialExtendRequest?: TrialExtendRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.extendTrial(id, trialExtendRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TrialApi.extendTrial']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Получить информацию о триале.
         * @param {string} id ИД триала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrial(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrial(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TrialApi.getTrial']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Отправить запрос на триал.
         * @param {TrialCreationRequest} [trialCreationRequest] Параметры запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestTrial(trialCreationRequest?: TrialCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestTrial(trialCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TrialApi.requestTrial']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Выполнить поиск триалов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTrials(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTrials(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TrialApi.searchTrials']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendEmail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmail(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TrialApi.sendEmail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TrialApi - factory interface
 * @export
 */
export const TrialApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrialApiFp(configuration)
    return {
        /**
         *
         * @summary Произвести активацию триала.
         * @param {string} [trialId] ИД триала.
         * @param {string} [userId] ИД пользователя.
         * @param {TrialAcceptionRequest} [trialAcceptionRequest] Запрос на активацию триала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateTrial(trialId?: string, userId?: string, trialAcceptionRequest?: TrialAcceptionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.activateTrial(trialId, userId, trialAcceptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Деактивировать триал.
         * @param {string} id ИД триала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTrial(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.cancelTrial(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Подтвердить активацию триала.
         * @param {string} verificationToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmTrial(verificationToken: string, options?: any): AxiosPromise<TrialConfirmationResponse> {
            return localVarFp.confirmTrial(verificationToken, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Установить новую дату окончания триала.
         * @param {string} id ИД триала.
         * @param {TrialExtendRequest} [trialExtendRequest] Новая дата окончания триала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendTrial(id: string, trialExtendRequest?: TrialExtendRequest, options?: any): AxiosPromise<object> {
            return localVarFp.extendTrial(id, trialExtendRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Получить информацию о триале.
         * @param {string} id ИД триала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrial(id: string, options?: any): AxiosPromise<TrialModel> {
            return localVarFp.getTrial(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Отправить запрос на триал.
         * @param {TrialCreationRequest} [trialCreationRequest] Параметры запроса.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestTrial(trialCreationRequest?: TrialCreationRequest, options?: any): AxiosPromise<TrialCreationResponse> {
            return localVarFp.requestTrial(trialCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Выполнить поиск триалов.
         * @param {object} [filtration] Параметры фильтрации.
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTrials(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<TrialModelPaginationResponse> {
            return localVarFp.searchTrials(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmail(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.sendEmail(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrialApi - object-oriented interface
 * @export
 * @class TrialApi
 * @extends {BaseAPI}
 */
export class TrialApi extends BaseAPI {
    /**
     *
     * @summary Произвести активацию триала.
     * @param {string} [trialId] ИД триала.
     * @param {string} [userId] ИД пользователя.
     * @param {TrialAcceptionRequest} [trialAcceptionRequest] Запрос на активацию триала.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialApi
     */
    public activateTrial(trialId?: string, userId?: string, trialAcceptionRequest?: TrialAcceptionRequest, options?: AxiosRequestConfig) {
        return TrialApiFp(this.configuration).activateTrial(trialId, userId, trialAcceptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Деактивировать триал.
     * @param {string} id ИД триала.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialApi
     */
    public cancelTrial(id: string, options?: AxiosRequestConfig) {
        return TrialApiFp(this.configuration).cancelTrial(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Подтвердить активацию триала.
     * @param {string} verificationToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialApi
     */
    public confirmTrial(verificationToken: string, options?: AxiosRequestConfig) {
        return TrialApiFp(this.configuration).confirmTrial(verificationToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Установить новую дату окончания триала.
     * @param {string} id ИД триала.
     * @param {TrialExtendRequest} [trialExtendRequest] Новая дата окончания триала.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialApi
     */
    public extendTrial(id: string, trialExtendRequest?: TrialExtendRequest, options?: AxiosRequestConfig) {
        return TrialApiFp(this.configuration).extendTrial(id, trialExtendRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Получить информацию о триале.
     * @param {string} id ИД триала.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialApi
     */
    public getTrial(id: string, options?: AxiosRequestConfig) {
        return TrialApiFp(this.configuration).getTrial(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Отправить запрос на триал.
     * @param {TrialCreationRequest} [trialCreationRequest] Параметры запроса.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialApi
     */
    public requestTrial(trialCreationRequest?: TrialCreationRequest, options?: AxiosRequestConfig) {
        return TrialApiFp(this.configuration).requestTrial(trialCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Выполнить поиск триалов.
     * @param {object} [filtration] Параметры фильтрации.
     * @param {number} [pageIndex]
     * @param {number} [pageSize]
     * @param {boolean} [withTotal]
     * @param {number} [fromItemInclusive]
     * @param {number} [toItemExclusive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialApi
     */
    public searchTrials(filtration?: object, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: AxiosRequestConfig) {
        return TrialApiFp(this.configuration).searchTrials(filtration, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrialApi
     */
    public sendEmail(id: string, options?: AxiosRequestConfig) {
        return TrialApiFp(this.configuration).sendEmail(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Принять приглашение пользователем.
         * @param {string} id ИД пользователя.
         * @param {AcceptInvitationRequest} [acceptInvitationRequest] Данные пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptUserInvitation: async (id: string, acceptInvitationRequest?: AcceptInvitationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('acceptUserInvitation', 'id', id)
            const localVarPath = `/api/v1/membership/users/{id}/invite/accept`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Заблокировать пользователя.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('blockUser', 'id', id)
            const localVarPath = `/api/v1/membership/users/{id}/block`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Изменить пароль пользователя.
         * @param {string} id ИД пользователя.
         * @param {PasswordChangingRequest} [passwordChangingRequest] Запрос изменения пароля.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPassword: async (id: string, passwordChangingRequest?: PasswordChangingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeUserPassword', 'id', id)
            const localVarPath = `/api/v1/membership/users/{id}/password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChangingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Создать пользователя.
         * @param {UserCreationRequest} [userCreationRequest] Запрос на создание пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userCreationRequest?: UserCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/membership/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Удалить пользователя.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/api/v1/membership/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Получить список пользователей.
         * @param {Array<string>} [ids] ИД пользователей.
         * @param {string} [searchText] Текст поиска.
         * @param {string} [tenantId] ИД тенанта.
         * @param {UserStatus} [status] Статус пользователя.
         * @param {string} [roleName] Роль пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers: async (ids?: Array<string>, searchText?: string, tenantId?: string, status?: UserStatus, roleName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/membership/users/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['Ids'] = ids;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['SearchText'] = searchText;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['TenantId'] = tenantId;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (roleName !== undefined) {
                localVarQueryParameter['RoleName'] = roleName;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Получить текущего пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/membership/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Получить пользователя.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/api/v1/membership/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Пригласить пользователя.
         * @param {UserInvitationRequest} [userInvitationRequest] Запрос на приглашение пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUser: async (userInvitationRequest?: UserInvitationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/membership/users/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Отправить повторное приглашение пользователю.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reInviteUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reInviteUser', 'id', id)
            const localVarPath = `/api/v1/membership/users/{id}/invitation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Восстановить пароль пользователя.
         * @param {string} id ИД пользователя.
         * @param {ResetPasswordRequest} [resetPasswordRequest] Запрос с новым паролем.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPassword: async (id: string, resetPasswordRequest?: ResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resetUserPassword', 'id', id)
            const localVarPath = `/api/v1/membership/users/{id}/password/reset`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Выполнить поиск пользователей.
         * @param {Array<string>} [ids] ИД пользователей.
         * @param {string} [searchText] Текст поиска.
         * @param {string} [tenantId] ИД тенанта.
         * @param {UserStatus} [status] Статус пользователя.
         * @param {string} [roleName] Роль пользователя.
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers: async (ids?: Array<string>, searchText?: string, tenantId?: string, status?: UserStatus, roleName?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/membership/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['Ids'] = ids;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['SearchText'] = searchText;
            }

            if (tenantId !== undefined) {
                localVarQueryParameter['TenantId'] = tenantId;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (roleName !== undefined) {
                localVarQueryParameter['RoleName'] = roleName;
            }

            if (pageIndex !== undefined) {
                localVarQueryParameter['PageIndex'] = pageIndex;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (withTotal !== undefined) {
                localVarQueryParameter['WithTotal'] = withTotal;
            }

            if (fromItemInclusive !== undefined) {
                localVarQueryParameter['FromItemInclusive'] = fromItemInclusive;
            }

            if (toItemExclusive !== undefined) {
                localVarQueryParameter['ToItemExclusive'] = toItemExclusive;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Разблокировать пользователя.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unlockUser', 'id', id)
            const localVarPath = `/api/v1/membership/users/{id}/unlock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Обновить текущего пользователя.
         * @param {UserUpdatingRequest} [userUpdatingRequest] Запрос на обновление текущего пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMe: async (userUpdatingRequest?: UserUpdatingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/membership/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Обновить пользователя.
         * @param {string} id ИД пользователя.
         * @param {UserUpdatingRequest} [userUpdatingRequest] Запрос на обновление пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, userUpdatingRequest?: UserUpdatingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            const localVarPath = `/api/v1/membership/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdatingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Принять приглашение пользователем.
         * @param {string} id ИД пользователя.
         * @param {AcceptInvitationRequest} [acceptInvitationRequest] Данные пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptUserInvitation(id: string, acceptInvitationRequest?: AcceptInvitationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptUserInvitation(id, acceptInvitationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.acceptUserInvitation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Заблокировать пользователя.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blockUser(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.blockUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Изменить пароль пользователя.
         * @param {string} id ИД пользователя.
         * @param {PasswordChangingRequest} [passwordChangingRequest] Запрос изменения пароля.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserPassword(id: string, passwordChangingRequest?: PasswordChangingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserPassword(id, passwordChangingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.changeUserPassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Создать пользователя.
         * @param {UserCreationRequest} [userCreationRequest] Запрос на создание пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userCreationRequest?: UserCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userCreationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.createUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Удалить пользователя.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.deleteUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Получить список пользователей.
         * @param {Array<string>} [ids] ИД пользователей.
         * @param {string} [searchText] Текст поиска.
         * @param {string} [tenantId] ИД тенанта.
         * @param {UserStatus} [status] Статус пользователя.
         * @param {string} [roleName] Роль пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsers(ids?: Array<string>, searchText?: string, tenantId?: string, status?: UserStatus, roleName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsers(ids, searchText, tenantId, status, roleName, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.getAllUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Получить текущего пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.getMe']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Получить пользователя.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.getUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Пригласить пользователя.
         * @param {UserInvitationRequest} [userInvitationRequest] Запрос на приглашение пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inviteUser(userInvitationRequest?: UserInvitationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inviteUser(userInvitationRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.inviteUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Отправить повторное приглашение пользователю.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reInviteUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInvitationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reInviteUser(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.reInviteUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Восстановить пароль пользователя.
         * @param {string} id ИД пользователя.
         * @param {ResetPasswordRequest} [resetPasswordRequest] Запрос с новым паролем.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetUserPassword(id: string, resetPasswordRequest?: ResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetUserPassword(id, resetPasswordRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.resetUserPassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Выполнить поиск пользователей.
         * @param {Array<string>} [ids] ИД пользователей.
         * @param {string} [searchText] Текст поиска.
         * @param {string} [tenantId] ИД тенанта.
         * @param {UserStatus} [status] Статус пользователя.
         * @param {string} [roleName] Роль пользователя.
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUsers(ids?: Array<string>, searchText?: string, tenantId?: string, status?: UserStatus, roleName?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchUsers(ids, searchText, tenantId, status, roleName, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.searchUsers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Разблокировать пользователя.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlockUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlockUser(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.unlockUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Обновить текущего пользователя.
         * @param {UserUpdatingRequest} [userUpdatingRequest] Запрос на обновление текущего пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMe(userUpdatingRequest?: UserUpdatingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMe(userUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.updateMe']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         *
         * @summary Обновить пользователя.
         * @param {string} id ИД пользователя.
         * @param {UserUpdatingRequest} [userUpdatingRequest] Запрос на обновление пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, userUpdatingRequest?: UserUpdatingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, userUpdatingRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.updateUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         *
         * @summary Принять приглашение пользователем.
         * @param {string} id ИД пользователя.
         * @param {AcceptInvitationRequest} [acceptInvitationRequest] Данные пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptUserInvitation(id: string, acceptInvitationRequest?: AcceptInvitationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.acceptUserInvitation(id, acceptInvitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Заблокировать пользователя.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.blockUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Изменить пароль пользователя.
         * @param {string} id ИД пользователя.
         * @param {PasswordChangingRequest} [passwordChangingRequest] Запрос изменения пароля.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPassword(id: string, passwordChangingRequest?: PasswordChangingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.changeUserPassword(id, passwordChangingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Создать пользователя.
         * @param {UserCreationRequest} [userCreationRequest] Запрос на создание пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userCreationRequest?: UserCreationRequest, options?: any): AxiosPromise<UserCreationResponse> {
            return localVarFp.createUser(userCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Удалить пользователя.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Получить список пользователей.
         * @param {Array<string>} [ids] ИД пользователей.
         * @param {string} [searchText] Текст поиска.
         * @param {string} [tenantId] ИД тенанта.
         * @param {UserStatus} [status] Статус пользователя.
         * @param {string} [roleName] Роль пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers(ids?: Array<string>, searchText?: string, tenantId?: string, status?: UserStatus, roleName?: string, options?: any): AxiosPromise<Array<UserModel>> {
            return localVarFp.getAllUsers(ids, searchText, tenantId, status, roleName, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Получить текущего пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options?: any): AxiosPromise<UserModel> {
            return localVarFp.getMe(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Получить пользователя.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: string, options?: any): AxiosPromise<UserModel> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Пригласить пользователя.
         * @param {UserInvitationRequest} [userInvitationRequest] Запрос на приглашение пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUser(userInvitationRequest?: UserInvitationRequest, options?: any): AxiosPromise<UserModel> {
            return localVarFp.inviteUser(userInvitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Отправить повторное приглашение пользователю.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reInviteUser(id: string, options?: any): AxiosPromise<UserInvitationResponse> {
            return localVarFp.reInviteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Восстановить пароль пользователя.
         * @param {string} id ИД пользователя.
         * @param {ResetPasswordRequest} [resetPasswordRequest] Запрос с новым паролем.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetUserPassword(id: string, resetPasswordRequest?: ResetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.resetUserPassword(id, resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Выполнить поиск пользователей.
         * @param {Array<string>} [ids] ИД пользователей.
         * @param {string} [searchText] Текст поиска.
         * @param {string} [tenantId] ИД тенанта.
         * @param {UserStatus} [status] Статус пользователя.
         * @param {string} [roleName] Роль пользователя.
         * @param {number} [pageIndex]
         * @param {number} [pageSize]
         * @param {boolean} [withTotal]
         * @param {number} [fromItemInclusive]
         * @param {number} [toItemExclusive]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers(ids?: Array<string>, searchText?: string, tenantId?: string, status?: UserStatus, roleName?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: any): AxiosPromise<UserModelPaginationResponse> {
            return localVarFp.searchUsers(ids, searchText, tenantId, status, roleName, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Разблокировать пользователя.
         * @param {string} id ИД пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.unlockUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Обновить текущего пользователя.
         * @param {UserUpdatingRequest} [userUpdatingRequest] Запрос на обновление текущего пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMe(userUpdatingRequest?: UserUpdatingRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateMe(userUpdatingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Обновить пользователя.
         * @param {string} id ИД пользователя.
         * @param {UserUpdatingRequest} [userUpdatingRequest] Запрос на обновление пользователя.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: string, userUpdatingRequest?: UserUpdatingRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateUser(id, userUpdatingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     *
     * @summary Принять приглашение пользователем.
     * @param {string} id ИД пользователя.
     * @param {AcceptInvitationRequest} [acceptInvitationRequest] Данные пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public acceptUserInvitation(id: string, acceptInvitationRequest?: AcceptInvitationRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).acceptUserInvitation(id, acceptInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Заблокировать пользователя.
     * @param {string} id ИД пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public blockUser(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).blockUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Изменить пароль пользователя.
     * @param {string} id ИД пользователя.
     * @param {PasswordChangingRequest} [passwordChangingRequest] Запрос изменения пароля.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public changeUserPassword(id: string, passwordChangingRequest?: PasswordChangingRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).changeUserPassword(id, passwordChangingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Создать пользователя.
     * @param {UserCreationRequest} [userCreationRequest] Запрос на создание пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(userCreationRequest?: UserCreationRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).createUser(userCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Удалить пользователя.
     * @param {string} id ИД пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Получить список пользователей.
     * @param {Array<string>} [ids] ИД пользователей.
     * @param {string} [searchText] Текст поиска.
     * @param {string} [tenantId] ИД тенанта.
     * @param {UserStatus} [status] Статус пользователя.
     * @param {string} [roleName] Роль пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAllUsers(ids?: Array<string>, searchText?: string, tenantId?: string, status?: UserStatus, roleName?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getAllUsers(ids, searchText, tenantId, status, roleName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Получить текущего пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getMe(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Получить пользователя.
     * @param {string} id ИД пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Пригласить пользователя.
     * @param {UserInvitationRequest} [userInvitationRequest] Запрос на приглашение пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public inviteUser(userInvitationRequest?: UserInvitationRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).inviteUser(userInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Отправить повторное приглашение пользователю.
     * @param {string} id ИД пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public reInviteUser(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).reInviteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Восстановить пароль пользователя.
     * @param {string} id ИД пользователя.
     * @param {ResetPasswordRequest} [resetPasswordRequest] Запрос с новым паролем.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public resetUserPassword(id: string, resetPasswordRequest?: ResetPasswordRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).resetUserPassword(id, resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Выполнить поиск пользователей.
     * @param {Array<string>} [ids] ИД пользователей.
     * @param {string} [searchText] Текст поиска.
     * @param {string} [tenantId] ИД тенанта.
     * @param {UserStatus} [status] Статус пользователя.
     * @param {string} [roleName] Роль пользователя.
     * @param {number} [pageIndex]
     * @param {number} [pageSize]
     * @param {boolean} [withTotal]
     * @param {number} [fromItemInclusive]
     * @param {number} [toItemExclusive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public searchUsers(ids?: Array<string>, searchText?: string, tenantId?: string, status?: UserStatus, roleName?: string, pageIndex?: number, pageSize?: number, withTotal?: boolean, fromItemInclusive?: number, toItemExclusive?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).searchUsers(ids, searchText, tenantId, status, roleName, pageIndex, pageSize, withTotal, fromItemInclusive, toItemExclusive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Разблокировать пользователя.
     * @param {string} id ИД пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public unlockUser(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).unlockUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Обновить текущего пользователя.
     * @param {UserUpdatingRequest} [userUpdatingRequest] Запрос на обновление текущего пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateMe(userUpdatingRequest?: UserUpdatingRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateMe(userUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Обновить пользователя.
     * @param {string} id ИД пользователя.
     * @param {UserUpdatingRequest} [userUpdatingRequest] Запрос на обновление пользователя.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(id: string, userUpdatingRequest?: UserUpdatingRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser(id, userUpdatingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



