import React, { useState } from 'react';
import { Form, Space } from 'antd';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbInput from '../../components/common/IbInput';
import IbButton from '../../components/common/IbButton';
import IbSpin from '../../components/common/IbSpin';
import { ForgotPasswordRequest } from '../../../api';
import { passwordApi } from '../../apis';
import IbInfo from '../../components/common/IbInfo';

import { MAIN_CLASS, BUTTON_CLASS, TITLE_CLASS } from './const';

const ForgotPasswordPage: React.FC = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const onRegisterButtonClick = () => push('/app/trial/request');
  const onBackButtonClick = () => push('/app/login');
  const onEmailChange = (value: string) => setEmail(value);

  const handleSubmit = async () => {
    setLoading(true);
    setErrorMessage('');

    const options = { withCredentials: true };

    const request: ForgotPasswordRequest = {
      nameOrEmail: email,
    };

    try {
      await passwordApi.forgotUserPassword(request, options);
      if (success) {
        setSuccessMessage(
          t('The repeated request to change the password has been sent successfully. Please check your email.')
        );
      }
      setSuccess(true);
      setErrorMessage('');
    } catch (e) {
      setErrorMessage(
        t(
          'The specified email is not registered in our database. Please check that you have filled it in correctly and try again.'
        )
      );
    }
    setLoading(false);
  };

  const renderSuccess = () => (
    <div className={MAIN_CLASS}>
      {loading && <IbSpin />}
      <div className={TITLE_CLASS}>{t('Request sent')}</div>
      {successMessage && <IbInfo status="success">{successMessage}</IbInfo>}
      <div className={`${MAIN_CLASS}__success-description`}>
        <span>
          {t(
            'The letter has been successfully sent to the address you specified. Please check your email and follow instructions for changing your password.'
          )}
        </span>
        <span>{t('If the letter did not arrive, try sending the request again.')}</span>
      </div>
      <div className={BUTTON_CLASS}>
        <IbButton type="fill" onClick={onBackButtonClick}>
          {t('Log in form')}
        </IbButton>
      </div>
      <div className={BUTTON_CLASS}>
        <IbButton disabled={!!successMessage} type="link" onClick={handleSubmit}>
          {t('Resend')}
        </IbButton>
      </div>
    </div>
  );

  return success ? (
    renderSuccess()
  ) : (
    <Form className={MAIN_CLASS} onFinish={handleSubmit}>
      {loading && <IbSpin />}
      <div className={TITLE_CLASS}>{t('Restore password')}</div>
      {errorMessage && <IbInfo status="error">{errorMessage}</IbInfo>}
      <div className={`${MAIN_CLASS}__form`}>
        <div className={`${MAIN_CLASS}__input-label`}>Email</div>
        <IbInput
          disabled={loading}
          status={errorMessage ? 'error' : 'default'}
          value={email}
          onChange={onEmailChange}
        />
        <div className={`${MAIN_CLASS}__description`}>
          {t(
            'Enter the email you used when registering and we will send you instructions on how to change your password'
          )}
        </div>
      </div>
      <div className={BUTTON_CLASS}>
        <IbButton disabled={!email || loading || !!successMessage} onClick={handleSubmit}>
          {t('Restore')}
        </IbButton>
      </div>
      <div className={BUTTON_CLASS}>
        <IbButton type="secondary" onClick={onBackButtonClick}>
          {t('Back')}
        </IbButton>
      </div>
      <div className={`${MAIN_CLASS}__register`}>
        <Space>
          {t("Don't have an account?")}
          <IbButton disabled={loading} type="link" onClick={onRegisterButtonClick}>
            {t('Register now')}
          </IbButton>
        </Space>
      </div>
    </Form>
  );
};

export default ForgotPasswordPage;
