import React, { ChangeEventHandler, ReactNode, MouseEventHandler, useRef } from 'react';

import './index.less';

import { getDefaultIfUndefined } from '../../../utils/typeUtil';
import IbButton from '../IbButton';
import IbIcon from '../IbIcon';

const STATUS_DEFAULT = 'default';
const MAIN_CLASS_NAME = 'ib-input';
const PREFIX_CLASS_NAME = `${MAIN_CLASS_NAME}__prefix`;
const SUFFIX_CLASS_NAME = `${MAIN_CLASS_NAME}__suffix`;
const SUFFIX_CLEAR_CLASS_NAME = `${SUFFIX_CLASS_NAME}__clear`;
const DISABLED_CLASS_NAME = `${MAIN_CLASS_NAME}_disabled`;

export interface IIbInputProps {
  className?: string;
  value?: null | string | number;
  status?: 'default' | 'success' | 'error';
  password?: boolean;
  disabled?: boolean;
  maxLength?: number;
  placeholder?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  allowClear?: boolean;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const IbInput: React.FC<IIbInputProps> = ({
  className,
  value,
  password,
  status = STATUS_DEFAULT,
  disabled,
  maxLength,
  placeholder,
  prefix,
  suffix,
  allowClear,
  autoFocus = false,
  onChange,
  onBlur,
  onKeyDown,
}) => {
  status = getDefaultIfUndefined(status, STATUS_DEFAULT);
  const inputRef = useRef<HTMLInputElement>(null);

  const classes = [MAIN_CLASS_NAME, `${MAIN_CLASS_NAME}_${status}`, disabled ? DISABLED_CLASS_NAME : null];
  className && classes.push(className);

  const onChangeInternal: ChangeEventHandler<HTMLInputElement> = (event) => onChange?.(event.target.value);
  const onDivClick: MouseEventHandler<HTMLDivElement> = (e) =>
    e.target === e.currentTarget && inputRef.current?.focus();

  const onClearInternal = () => onChange?.('');

  const renderPrefix = () => {
    if (!prefix) {
      return null;
    }

    return <div className={PREFIX_CLASS_NAME}>{prefix}</div>;
  };

  const renderSuffix = () => {
    if (!suffix && !allowClear && !value) {
      return null;
    }

    return (
      <div className={SUFFIX_CLASS_NAME}>
        {allowClear && !!value && (
          <div className={SUFFIX_CLEAR_CLASS_NAME}>
            <IbButton icon={<IbIcon iconName="close" />} type="icon" onClick={onClearInternal}></IbButton>
          </div>
        )}
        {suffix}
      </div>
    );
  };

  return (
    <div className={classes.join(' ')} onClick={onDivClick}>
      {renderPrefix()}
      <input
        ref={inputRef}
        autoFocus={autoFocus}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        type={password ? 'password' : 'text'}
        value={value !== null ? value : undefined}
        onBlur={onBlur}
        onChange={onChangeInternal}
        onKeyDown={onKeyDown}
      />
      {renderSuffix()}
    </div>
  );
};

export default IbInput;
