import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';

const tryExtractErrorsFirstMessage = (errorData?: { [key: string]: string }) => {
  let errors = Object.values(errorData?.errors || {});

  if (!errors.length) {
    errors = Object.keys(errorData || {})
      .map((key) => (key.startsWith('identity_') ? errorData?.[key] : ''))
      .filter((str) => !!str)
      .map((str) => str as string);
  }

  return errors.length ? errors[0]?.toString() : errorData;
};

export const getErrorMessage = (error: Error): string => {
  let message = '';

  const axiosError = error as AxiosError;
  if (axiosError.isAxiosError && axiosError.response) {
    message =
      axiosError.response.status === StatusCodes.UNPROCESSABLE_ENTITY
        ? tryExtractErrorsFirstMessage(axiosError.response.data)
        : axiosError.response.status < StatusCodes.INTERNAL_SERVER_ERROR
        ? axiosError.response.data.detail || axiosError.response.data
        : 'Внутренняя ошибка сервера';
  }

  return message || error.message || String(error);
};
